import React, { useState } from 'react';
import ReplaceOrderTable from '../replaceOrderTable';
import { useLocation } from 'react-router-dom';
import { RetweetOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Product } from '../../../schema/order';

function ReplaceOrder() {
  const [selectedProduct, setSelectedProduct] = useState<Product[]>([]);
  const [visibleReplaceOrderTable, setVisibleReplaceOrderTable] = useState<boolean>(true);
  const [visibleReplaceSomeOrderTable, setVisibleReplaceSomeOrderTable] = useState<boolean>(false);
  const location = useLocation();
  const orderProps = location.state;

  const updateParentState = (newValue: any) => {
    setSelectedProduct(newValue);
  };
  const handleSomeClick = () => {
    setVisibleReplaceOrderTable(false);
    setVisibleReplaceSomeOrderTable(true);
  };

  const handleReplaceOrderClick = () => {
    setVisibleReplaceOrderTable(true);
    setVisibleReplaceSomeOrderTable(false);
  };
  return (
    <div>
      <div className='px-2'>
        <div className='d-flex' style={{ justifyContent: 'space-between' }}>
          <div className='d-flex gap-4'>
            <div className='d-flex flex-column'>
              <span>
                <b>Name:</b> {orderProps.userRecord?.name}
              </span>
              <span>
                <b>Type:</b> {orderProps.userRecord.type}
              </span>
              <span>
                <b>Total Ordered:</b> {orderProps.userRecord.totalOrdered}
              </span>
            </div>
            <RetweetOutlined style={{ fontSize: '20px' }} />
            <div className='d-flex flex-column'>
              <span>
                <b>Name:</b> {selectedProduct[0]?.name}
              </span>
              <span>
                <b>Type:</b> {selectedProduct[0]?.type}
              </span>
              <span>
                <b>Total Stock:</b> {selectedProduct[0]?.totalStock}
              </span>
            </div>
          </div>
          <div className='align-self-center d-flex flex-column gap-2'>
            <Button
              onClick={handleReplaceOrderClick}
              className='d-flex table-title-row justify-content-center'
              type={visibleReplaceOrderTable ? 'primary' : 'dashed'}
            >
              Replace All
            </Button>
            <Button
              onClick={handleSomeClick}
              className='d-flex table-title-row justify-content-center'
              type={visibleReplaceSomeOrderTable ? 'primary' : 'dashed'}
            >
              Replace Some Orders
            </Button>
          </div>
        </div>
        <hr className='my-2'></hr>
      </div>
      <ReplaceOrderTable
        selectedOrderFormId={orderProps.selectedOrderFormId}
        recordId={orderProps.recordId}
        userRecord={orderProps.userRecord}
        visibleReplaceOrderTable={visibleReplaceOrderTable}
        visibleReplaceSomeOrderTable={visibleReplaceSomeOrderTable}
        onUpdate={updateParentState}
      />
    </div>
  );
}

export default ReplaceOrder;
