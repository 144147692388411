import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, DatePicker, Input, InputNumber, Modal, Select, Table, Tooltip, message } from 'antd';
import api from '../../../config/axios';
import { customerData } from '../../../config/api-routes';
import { Customer } from '../../../schema/user';
import Search from 'antd/es/input/Search';
import { debounce } from 'lodash';
import { ColumnsType } from 'antd/es/table';
import { Files } from '../../../schema/order';
import { EditOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import Form from 'antd/lib/form';
import { PhoneNumberField } from '@aws-amplify/ui-react';
import { province } from '../../../common/province';
import { useNavigate } from 'react-router-dom';

function GetCustomers() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Customer[]>();
  const [loader, setLoader] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [editForm] = Form.useForm();
  const history = useNavigate();


  const customerTable: ColumnsType<Customer> = [
    {
      title: 'Customer#',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Contact Name',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Business Name',
      dataIndex: 'businessName',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      render: (text: any) => <span>+1 {text}</span>,
      align: 'center',
      width: 150,
    },
    {
      title: 'Street Address',
      dataIndex: 'streetAddress',
      align: 'center',
    },
    {
      title: 'Address Line 2',
      dataIndex: 'addressLine2',
      align: 'center',
      render: (text: any) => <span>{!text ? '-' : text}</span>,
    },
    {
      title: 'City',
      dataIndex: 'city',
      align: 'center',
    },
    {
      title: 'Province',
      dataIndex: 'province',
      align: 'center',
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      align: 'center',
    },
    {
      title: 'Tax',
      dataIndex: 'taxNo',
      align: 'center',
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: (_, record: Files | any) => (
        <div className='d-flex gap-4 justify-content-center'>
          <Tooltip title='Edit'>
            <EditOutlined onClick={() => editFiles(record)} />
          </Tooltip>
          <Tooltip title='Shopping Cart'>
            <ShoppingCartOutlined onClick={() => handleShoppingCartClick(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCustomers('');
  }, []);

  const handleShoppingCartClick = (record: any) => {
    // Assuming you have a route like '/shopping-cart/:id'
    const route = `/customerOrderForm/${record.cognitoId}`;
    // Redirect to the new page
    history(route);
  };

  const editFiles = (record: any) => {
    setVisible(true);
    const formData = {
      id: record.id,
      businessName: record.businessName,
      name: record.name,
      email: record.email,
      phoneNumber: record.phoneNumber,
      streetAddress: record.streetAddress,
      addressLine2: record.addressLine2,
      city: record.city,
      province: record.province,
      postalCode: record.postalCode,
      taxNo: record.taxNo,
      cognitoId: record.cognitoId
    };

    setVisible(true);
    editForm.setFieldsValue(formData);
  };


  const onCancel = () => {
    setVisible(false);
  };

  const handleSave = () => {
    editForm
      .validateFields()
      .then((values: any) => {
        values = { ...values };
        setLoader(true);
        if (values !== 0) {
          api
            .put<never, any>(`/${customerData}/${values.cognitoId}`, values)
            .then((res: any) => {
              if (res) {
                message.success(res);
                setLoader(false);
                setVisible(false);
                getCustomers('');

              }
            })
            .catch((error: any) => {
              message.error(error?.response?.data?.message);
              setLoader(false);
              setVisible(false);
            });
        }
      })
      .catch(() => {
        return false;
      });
  };

  const getCustomers = async (searchInput?: string | '') => {
    setLoading(true);
    await api
      .get<never, Customer[]>(`${customerData}/?search=${searchInput}`)
      .then((res) => {
        const dataSource = res;
        setData(dataSource);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
      });
  };

  const handleInputChange = (event: any) => {
    if (event) {
      const searchValue = event?.target?.value;
      getCustomers(searchValue);
    }
  };
  const checkForBlankSpace = (rule: any, value: string) => {
    return new Promise<void>((resolve, reject) => {
      if (value && value.trim() === '') {
        reject('Field value cannot have a space');
      } else {
        resolve();
      }
    });
  };

  const validatePhoneNumber = (rule: any, value: string) => {
    return new Promise<void>((resolve, reject) => {
      const phoneRegex = /^(\(\+[0-9]{2}\))?([0-9]{3}-?)?([0-9]{3})-?([0-9]{4})(\/[0-9]{4})?$/;
      if (!value || phoneRegex.test(value)) {
        resolve();
      } else {
        reject('Please enter a valid phone number');
      }
    });
  };

  const debouncedHandleInputChange = debounce(handleInputChange, 1000);

  return (
    <div className='px-2'>
      <div>
        <Modal
          open={visible}
          title={'Edit Customer'}
          onCancel={onCancel}
          onOk={handleSave}
          destroyOnClose
          footer={[
            <Button key='back' onClick={onCancel}>
              Cancel
            </Button>,
            <Button key='submit' type='primary' loading={loader} onClick={handleSave}>
              Submit
            </Button>,
          ]}
        >
          <Form form={editForm} name='orderInventoryForm' layout='vertical'>
            <div className='d-flex gap-2'>
              <Form.Item
                label='Contact Name'
                name='name'
                className='customer-edit-form-child'
                rules={[
                  { required: true, message: 'Please enter Contact Name!' },
                  {
                    validator: checkForBlankSpace,
                  },
                ]}
              >
                <Input
                  className='w-100'
                  type='string'
                  placeholder='Enter Contact Name'
                />
              </Form.Item>

              <Form.Item
                label='Business Name'
                name='businessName'
                className='customer-edit-form-child'
                rules={[
                  { required: true, message: 'Please enter Business Name!' },
                  {
                    validator: checkForBlankSpace,
                  },
                ]}
              >
                <Input
                  className='w-100'
                  type='string'
                  placeholder='Enter Business Name'
                />
              </Form.Item>
            </div>
            <div className='d-flex gap-2'>
              <Form.Item
                label='Email'
                name='email'
                className='customer-edit-form-child'
              >
                <Input
                  className='w-100'
                  type='string'
                  disabled
                />
              </Form.Item>
              <Form.Item
                name='phoneNumber'
                label='Phone Number'
                className='customer-edit-form-child'
                rules={[
                  { required: true, message: 'Please enter phone number!' },
                  {
                    validator: checkForBlankSpace,
                  },
                  {
                    validator: validatePhoneNumber,
                  },
                ]}
              >
                <Input addonBefore='+1' type='tel'
                  placeholder='Enter Phone Number' />
              </Form.Item>
            </div>
            <div className='d-flex gap-2'>
              <Form.Item
                label='Street Address'
                name='streetAddress'
                className='customer-edit-form-child'
                rules={[
                  { required: true, message: 'Please enter Street Address!' },
                  {
                    validator: checkForBlankSpace,
                  },
                ]}
              >
                <Input
                  className='w-100'
                  type='string'
                  placeholder='Enter Street Address'
                />
              </Form.Item>
              <Form.Item
                label='Address Line 2'
                name='addressLine2'
                className='customer-edit-form-child'
              >
                <Input
                  className='w-100'
                  type='string'
                  placeholder='Enter Address Line 2'
                />
              </Form.Item>
            </div>
            <div className='d-flex gap-2'>
              <Form.Item
                label='City'
                name='city'
                className='customer-edit-form-child'
                rules={[
                  { required: true, message: 'Please enter City!' },
                  {
                    validator: checkForBlankSpace,
                  },
                ]}
              >
                <Input
                  className='w-100'
                  type='string'
                  placeholder='Enter City'
                />
              </Form.Item>

              <Form.Item
                label='Province'
                name='province'
                className='customer-edit-form-child'
                rules={[{ required: true, message: 'Please select province!' }]}
              >
                <Select className='customer-edit-form-child' placeholder={'Select Province'} options={province}></Select>
              </Form.Item>
            </div>
            <div className='d-flex gap-2'>
              <Form.Item
                label='Postal Code'
                name='postalCode'
                className='customer-edit-form-child'
                rules={[
                  { required: true, message: 'Please enter Postal Code!' },
                  {
                    validator: checkForBlankSpace,
                  },
                ]}
              >
                <Input
                  className='w-100'
                  type='string'
                  placeholder='Enter Postal Code'
                />
              </Form.Item>

              <Form.Item
                label='Tax'
                name='taxNo'
                className='customer-edit-form-child'
              >
                <Input
                  className='w-100'
                  type='string'
                  placeholder='Enter Tax'
                />
              </Form.Item>
              <Form.Item
                label='cognitoId'
                name='cognitoId'
                hidden
              >
                <Input
                  className='w-100'
                  type='string'
                  hidden
                />
              </Form.Item>
              <Form.Item
                label='Id'
                name='id'
                hidden
              >
                <InputNumber
                  className='w-100'
                  type='number'
                  hidden
                />
              </Form.Item>

            </div>
          </Form>
        </Modal>
      </div>
      <h3 className='title-grey'>Customers</h3>
      <span className='search-row d-flex py-2 justify-content-end'>
        <Search
          placeholder='Search'
          onChange={debouncedHandleInputChange}
          allowClear
          size='large'
          style={{ width: 500 }}
        />
      </span>
      <Table
        pagination={{ pageSize: 100, showSizeChanger: false }}
        bordered
        columns={customerTable}
        loading={loading}
        dataSource={data}
        rowKey='id'
      />
    </div>
  );
}

export default GetCustomers;
