import { Button, Form, FormInstance, InputNumber, Modal, Table, message } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Product } from '../../../schema/order';
import api from '../../../config/axios';
import { addProductByAdmin, getOrderFormProducts } from '../../../config/api-routes';

const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Product;
  record: Product;
  handleSave: (record: Product) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing /* setEditing */] = useState(false);
  const inputRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = useContext(EditableContext)!;
  const [inputValue] = useState<string | number | null>(0);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const save = async (record: any) => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const getValue = (record: any) => {
    if (record.quantity) {
      return record.quantity;
    } else {
      return 0;
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{ margin: 0, width: '50px' }}
        name={dataIndex}
        initialValue={getValue(record)}
      >
        <InputNumber
          width={'50%'}
          type='number'
          ref={inputRef}
          min={0}
          placeholder='Enter Quantity'
          value={inputValue}
          onPressEnter={() => save(record)}
          onBlur={() => save(record)}
        />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const AddNewProduct = ({ visible, onClose, orderFormId, products, orderId }: any) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<Product[]>([]);
  const [addProductLoading, setAddProductLoading] = useState(false);

  useEffect(() => {
    getOrders();
  }, []);

  message.config({
    maxCount: 1, // Maximum number of messages to display at once
  });

  const closeModal = (res: any) => {
    onClose(res);
  };

  const getOrders = async () => {
    setLoading(true);
    await api.get(getOrderFormProducts + '/' + orderFormId).then((res: any) => {
      const results = res;
      setDataSource(results);
      setLoading(false);
    });
  };

  const handleSubmit = async () => {
    const selectedQuantityProducts = dataSource.filter((item: any) => item.quantity > 0);
    if (selectedQuantityProducts.length > 0) {
      setAddProductLoading(true);
      const payload = {
        products: selectedQuantityProducts,
        orderId: orderId,
        sendEmail: false,
      };

      await api
        .put(`${addProductByAdmin}`, payload)
        .then((res: any) => {
          message.success(res?.message);
          setAddProductLoading(false);
          closeModal(res?.message);
        })
        .catch((error: any) => {
          setAddProductLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else {
      message.error('Please enter at least one quantity');
    }
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: ' Quantity ',
      dataIndex: 'quantity',
      editable: true,
      align: 'right',
      render: (text) => (text > 0 ? <span>{Number(text ? text : 0)}</span> : <span>&nbsp;</span>),
      width: 100,
    },
    {
      title: 'Variety',
      dataIndex: 'name',
      width: 300,
      render: (_: any, record: any) =>
        record.imageUrl ? (
          <a
            href={
              record.imageUrl.indexOf('http') > -1 ? record.imageUrl : `https://${record.imageUrl}`
            }
            target='_blank'
            rel='noreferrer'
          >
            {record.name}
          </a>
        ) : (
          <span>{record.name}</span>
        ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 400,
    },
    {
      title: 'Available Stock',
      dataIndex: 'totalStock',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      width: 100,
    },
    {
      title: 'Tier One',
      dataIndex: 'tier1Price',
      align: 'right',
      render: (text) => <span>${Number(text).toFixed(2)}</span>,
      width: 80,
    },
    {
      title: 'Tier Two',
      dataIndex: 'tier2Price',
      align: 'right',
      render: (text) => <span>${Number(text).toFixed(2)}</span>,
      width: 80,
    },
    {
      title: 'Tier Three',
      dataIndex: 'tier3Price',
      align: 'right',
      render: (text) => <span>${Number(text).toFixed(2)}</span>,
      width: 80,
    },
  ];

  const handleSave = (product: Product) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => product.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...product,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Product) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      maskClosable={false}
      width={'100%'}
      destroyOnClose
      bodyStyle={{ padding: 0, height: '85vh' }}
      title={<h3 className='title-grey'>Add Products</h3>}
    >
      <div style={{ height: '100%', overflow: 'auto' }}>
        <div className='d-flex justify-content-end pb-2'>
          <Button type='primary' onClick={handleSubmit} loading={addProductLoading}>
            Submit
          </Button>
        </div>

        <Table
          loading={loading}
          components={components}
          scroll={{ y: '70vh' }}
          rowClassName={() => 'editable-row'}
          rowKey='id'
          bordered
          pagination={false}
          dataSource={dataSource}
          columns={columns as ColumnTypes}
        />
      </div>
    </Modal>
  );
};

export default AddNewProduct;
