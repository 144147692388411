import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, InputNumber, Table, message } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { UserOrders } from '../../schema/order';
import { SyncOutlined } from '@ant-design/icons';
import { bulkQuantity, getAllOrdersForProduct } from '../../config/api-routes';
import api from '../../config/axios';

const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof UserOrders;
  record: UserOrders;
  handleSave: (record: any) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing /* setEditing */] = useState(false);
  const inputRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = useContext(EditableContext)!;
  const [inputValue] = useState<string | number | null>(0);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const save = async (record: any) => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const getValue = (record: any) => {
    if (record.quantity) {
      return record.quantity;
    } else {
      return 0;
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{ margin: 0, width: '50px' }}
        name={dataIndex}
        initialValue={getValue(record)}
      >
        <InputNumber
          width={'50%'}
          // type='number'
          ref={inputRef}
          min={0}
          placeholder='Enter Quantity'
          value={inputValue}
          onPressEnter={() => save(record)}
          onBlur={() => save(record)}
        />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function OrdersByNameTable({ recordId, closeModal, userRecord }: any) {
  const [dataSource, setDataSource] = useState<UserOrders[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await api
      .put(`${bulkQuantity}/${recordId}`, { productOrders: dataSource })
      .then((res: any) => {
        message.success(res?.message);
        closeModal(res?.message);
      })
      .catch((error: any) => {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (Number(recordId) > 0) {
      fetchOrdersById();
    }
  }, [recordId]);

  const fetchOrdersById = () => {
    setLoading(true);
    api
      .get<never, UserOrders[]>(`${getAllOrdersForProduct}/${Number(recordId)}`)
      .then((result) => {
        setDataSource(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const calculateTotal = () => {
    const filteredEmpty = dataSource.filter(
      (item) => item.quantity !== 0 && item.quantity !== undefined,
    );
    const total = filteredEmpty.reduce((acc, item) => acc + item.quantity, 0);
    return total;
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: ' Quantity ',
      dataIndex: 'quantity',
      editable: true,
      align: 'center',
      render: (text) => (text > 0 ? <span>{Number(text ? text : 0)}</span> : <span>&nbsp;</span>),
      width: 100,
    },
    {
      title: 'Ordered Quantity',
      dataIndex: 'orderedQuantity',
      align: 'center',
      render: (_: any, record: any) => <span>{record.orderedQuantity}</span>,
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      align: 'center',
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      align: 'center',
      render: (_: any, record: any) => <span>{record.orderNumber}</span>,
    },
    {
      title: 'Applied Tier',
      dataIndex: 'appliedTier',
      align: 'center',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Price per piece',
      dataIndex: 'pricePerPiece',
      align: 'center',
      render: (text) => <span>$ {text}</span>,
    },
  ];

  const handleSave = (product: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => product.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...product,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
        <div className='d-flex flex-column'>
          <span>
            <b>Name:</b> {userRecord?.name}
          </span>
          <span>
            <b>Type:</b> {userRecord?.type}
          </span>
          <span>
            <b>Size:</b> {userRecord?.size}
          </span>
        </div>
        <span className='font-20'>
          <b>Total Quantity:</b> {calculateTotal()}
        </span>
        <Button
          onClick={handleSubmit}
          className='d-flex table-title-row'
          type='primary'
          icon={<SyncOutlined />}
        >
          Update
        </Button>
      </div>
      <Table
        loading={loading}
        components={components}
        scroll={{ y: '55vh' }}
        rowClassName={() => 'editable-row'}
        rowKey='id'
        bordered
        pagination={false}
        dataSource={dataSource}
        columns={columns as ColumnTypes}
      />
    </div>
  );
}

export default OrdersByNameTable;
