import { Button, DatePicker, Select, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import {
  exportProductLabels,
  getAllProductLines,
  getDistinctYear,
  getOrderFormsByYear,
} from '../../../config/api-routes';
import api from '../../../config/axios';
import { Years } from '../../../schema/order';

const ProductLabelsReport = () => {
  const [productTypes, setProductTypes] = useState([]);
  const [productLabels, setProductLabels] = useState([]);

  const [inlineLoading, setInlineLoading] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [orderFormLoading, setOrderFormLoading] = useState(false);
  const [yearLoading, setYearLoading] = useState(false);

  const [selectedProductLine, setSelectedProductLine] = useState<number | null>(0);
  const [distinctYears, setDistinctYears] = useState([]);
  const [selectedDistinctYear, setSelectedDistinctYear] = useState<Years | ''>();
  const [orderFormsDropdown, setOrderFormsDropdown] = useState<any[]>([]);
  const [selectedOrderFormId, setSelectedOrderFormId] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [selectedValue, setSelectedValue] = useState(null);
  const [dateValue, setDateValue] = useState(null);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    getAllProductTypes();
  }, []);

  const columns = [
    {
      title: 'Available Quantity',
      dataIndex: 'totalStock',
    },
    {
      title: 'Variety',
      dataIndex: 'name',
    },
    {
      title: 'Size',
      dataIndex: 'size',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
  ];

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Create the "Product Labels" sheet
    const productLabelData = [['Available Quantity', 'Variety', 'Size', 'Type']];
    productLabels.forEach((pl: any) => {
      productLabelData.push([pl.totalStock, pl.name, pl.size, pl.type]);
    });
    const productLabelSheet = XLSX.utils.aoa_to_sheet(productLabelData);
    XLSX.utils.book_append_sheet(workbook, productLabelSheet, 'Product Labels');

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const newdate = day + '-' + month + '-' + year + '_' + time;

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'ProductLabelsData_' + newdate + '.xlsx');
  };

  const getProductLabels = async () => {
    setOrdersLoading(true);
    await api
      .post(exportProductLabels, {
        productLineId: selectedProductLine,
        orderFormId: selectedOrderFormId,
        startDate: startDate,
        endDate: endDate
      })
      .then((result: any) => {
        setProductLabels(result);
        setOrdersLoading(false);
      })
      .catch((error: any) => {
        setOrdersLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const getAllProductTypes = async () => {
    setInlineLoading(true);
    await api
      .get(getAllProductLines)
      .then((result: any) => {
        const response = result;
        const productTypesList = response.map((object: any) => ({
          id: object.id,
          value: object.name,
        }));
        setProductTypes(productTypesList);
        setInlineLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setInlineLoading(false);
      });
  };

  const handleProductLineChange = async (value: any, event: any) => {
    clearFilters();
    if (event && event.id) {
      const productLineId = event.id;
      setSelectedProductLine(productLineId);
      getDistinctYears(productLineId);

      setSelectedOrderFormId(0);
      setOrderFormsDropdown([]);
    } else {
      setSelectedProductLine(0);
      setSelectedOrderFormId(0);
      setSelectedDistinctYear(undefined);
      setOrderFormsDropdown([]);
      setDistinctYears([]);
      setSelectedValue(null);
      setProductLabels([]);
      setDateValue(null);
      setStartDate('');
      setEndDate('');
    }
  };

  const clearFilters = () => {
    setSelectedDistinctYear(undefined);
    setOrderFormsDropdown([]);
    setSelectedValue(null);
    setSelectedOrderFormId(0);
    setProductLabels([]);
  };

  const onDistinctYearChange = (item: any, event: any) => {
    if (event) {
      const selectedYear = event.value;
      setSelectedDistinctYear(event);
      getOrderForms(selectedProductLine, selectedYear);
    } else {
      setSelectedDistinctYear(undefined);
    }
  };

  const orderFormChange = (event: any) => {
    if (event) {
      const selectedOrderFormId = event.key;
      setSelectedOrderFormId(selectedOrderFormId);
      setSelectedValue(event);
    } else {
      setSelectedValue(null);
      setSelectedOrderFormId(0);
    }
  };

  const getDistinctYears = (selectedProductId: any) => {
    setYearLoading(true);
    api
      .get(`${getDistinctYear}/${selectedProductId}`)
      .then((result: any) => {
        const response = result;
        const transformedYearList = response.map((item: any, index: any) => ({
          id: index + 1,
          value: Object.values(item)[0],
        }));

        // If current year found in list , set it selected
        const currentDate = new Date();
        const currentYear = transformedYearList.filter(
          (item: any) => item.value == currentDate.getFullYear(),
        );

        if (currentYear.length) {
          setSelectedDistinctYear(currentYear);
          getOrderForms(selectedProductId, currentYear[0].value);
        }

        setDistinctYears(transformedYearList);
        setYearLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setYearLoading(false);
      });
  };

  const getOrderForms = async (productId: any, selectedYear: any) => {
    setOrderFormLoading(true);
    await api
      .get<never, any>(`${getOrderFormsByYear}/${productId}?year=${selectedYear}`)
      .then((res: any) => {
        const orderFormResponse = res;
        const orderFormdd = [];
        for (let i = 0; i < orderFormResponse.length; i++) {
          const object = orderFormResponse[i];
          orderFormdd.push({
            id: object.id,
            value: object.id,
            title: object.title,
            key: object.id,
            label: object.title,
          });
        }

        setOrderFormsDropdown(orderFormdd);
        setOrderFormLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setOrderFormLoading(false);
      });
  };

  function onCalendarChange(dates: any) {
    setDateValue(dates);
    if (dates != null) {
      dates.map((dateString: string, index: number) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);

        const formattedDate = `${year}-${month}-${day}`;
        if (index == 0) {
          setStartDate(formattedDate);
          setEndDate('');
        }
        else { setEndDate(formattedDate); }

      });
    }
    else {
      setStartDate('');
      setEndDate('');
    }
  }

  const fetchRecords = () => {
    getProductLabels();
  };

  return (
    <div className='reports-tab-container'>
      <div className='d-flex align-items-center justify-content-center mb-2'>
        <span className='d-flex gap-2'>
          <Select
            placeholder={'Select Product Line'}
            allowClear
            options={productTypes}
            loading={inlineLoading}
            style={{ width: '200px' }}
            onChange={(value, event) => handleProductLineChange(value, event)}
          ></Select>
          <Select
            placeholder={'Select Year'}
            allowClear
            value={selectedDistinctYear}
            options={distinctYears}
            style={{ width: '200px' }}
            loading={yearLoading}
            labelInValue
            onChange={(value, event) => onDistinctYearChange(value, event)}
          ></Select>
          <Select
            placeholder={'Select Order Form'}
            labelInValue
            allowClear
            value={selectedValue}
            loading={orderFormLoading}
            options={orderFormsDropdown}
            onChange={orderFormChange}
            style={{ width: '200px' }}
          ></Select>
          <RangePicker
            onCalendarChange={onCalendarChange}
            value={dateValue}
          />,
          <Button
            type='primary'
            disabled={selectedProductLine == 0 && startDate == '' && endDate == '' || selectedOrderFormId == 0 && startDate == '' && endDate == '' || ordersLoading || selectedProductLine != 0 && startDate != '' && endDate == '1970-01-01' || selectedProductLine != 0 && startDate == '1970-01-01' && endDate != ''}
            onClick={fetchRecords}
          >
            Fetch
          </Button>
          <Button
            type='primary'
            disabled={!productLabels.length || ordersLoading}
            onClick={exportToExcel}
          >
            Export
          </Button>
        </span>
      </div>
      <Table
        pagination={{ pageSize: 100, showSizeChanger: false }}
        bordered
        loading={ordersLoading}
        columns={columns}
        dataSource={productLabels}
        rowKey='id'
      />
    </div>
  );
};

export default ProductLabelsReport;
