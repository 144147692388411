import CryptoJS from 'crypto-js';

// Encrypt
export function encryptString(str, secret) {
  const userToken = '#UnicornBlooms#';
  return CryptoJS.DES.encrypt(str, `${secret ? secret : userToken}`).toString();
}

// Decrypt
export function decryptString(ciphertext) {
  const userToken = '#UnicornBlooms#';
  const bytes = CryptoJS.DES.decrypt(ciphertext, `${userToken}`);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}
