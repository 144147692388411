import React, { useEffect, useState } from 'react';
import OrderTable from './orderTable';
import propTypes from 'prop-types';
import { Order } from '../../schema/order';
import { LinkOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
type StepOneProps = {
  order: Order;
  onChange: (data: any) => void;
};
function StepOne({ order }: StepOneProps) {
  const [data, setData] = useState<Order>(order);

  useEffect(() => {
    setData(order);
  }, [order]);

  return (
    <div>
      <div className='d-flex flex-column bottom-space font-14'>
        <div>
          <span>
            Minimum order <b>${order.minOrderAmt.toFixed(2)}</b> plus tax and shipping
          </span>
        </div>

        <div className='d-flex gap-3 py-2'>
          <div className='column form-card flex-grow-1'>
            <div>
              <div>Products are sold in multiples:</div>
              {order.orderTiers.map((tier) => (
                <div key={tier.id} style={{ display: 'flex' }}>
                  <div>
                    <span>
                      <b>{tier.type}</b> sold
                      {tier.multiple == 1 && (
                        <span>
                          <b> singly</b>
                        </span>
                      )}
                      {tier.multiple != 1 && (
                        <span>
                          {' '}
                          in multiples of <b>{tier.multiple}</b>
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='column form-card flex-grow-1'>
            {order.isTierQuantityBased && (
              <div>
                <div>Prices are based on total quantity: </div>
                {order.isTierSameForAll && (
                  <div className='spans' style={{ display: 'grid' }}>
                    {order.orderTiers[0].tier1Qty != 0 &&
                      order.orderTiers[0].tier2Qty != 0 &&
                      order.orderTiers[0].tier1Qty != 0 && (
                        <>
                          <span className='tier-span'>
                            Tier One $ ea @
                            <b>
                              {' <'} {order.orderTiers[0].tier2Qty! - order.orderTiers[0].multiple!}{' '}
                              Pcs
                            </b>
                          </span>
                          <span className='tier-span'>
                            Tier Two $ ea @
                            <b>
                              {' '}
                              {order.orderTiers[0].tier2Qty!}
                              {' - '}{' '}
                              {order.orderTiers[0].tier3Qty! - order.orderTiers[0].multiple!} Pcs
                            </b>
                          </span>
                          <span className='tier-span'>
                            Tier Three $ ea @
                            <b>
                              {' >='} {order.orderTiers[0].tier3Qty} Pcs
                            </b>
                          </span>
                        </>
                      )}

                    {order.orderTiers[0].tier1Qty != 0 &&
                      order.orderTiers[0].tier2Qty != 0 &&
                      order.orderTiers[0].tier1Qty == 0 && (
                        <>
                          <span className='tier-span'>
                            Tier One:
                            <b>
                              {' '}
                              0 - {order.orderTiers[0].tier1Qty! -
                                order.orderTiers[0].multiple!}{' '}
                              Pcs
                            </b>
                          </span>
                          <span className='tier-span'>
                            Tier Two:
                            <b>
                              {' '}
                              {'>='} {order.orderTiers[0].tier1Qty} Pcs
                            </b>
                          </span>
                        </>
                      )}

                    {order.orderTiers[0].tier1Qty != 0 &&
                      order.orderTiers[0].tier2Qty == 0 &&
                      order.orderTiers[0].tier1Qty == 0 && (
                        <>
                          <span className='tier-span'>
                            Crate:
                            <b> {order.orderTiers[0].tier1Qty} Pcs</b>
                          </span>
                        </>
                      )}
                  </div>
                )}
                {!order.isTierSameForAll &&
                  order.orderTiers.map((tier) => (
                    <div key={tier.id} style={{ display: 'flex' }}>
                      {tier.tier1Qty != 0 && tier.tier2Qty != 0 && tier.tier3Qty != 0 && (
                        <div className='spans align-items-center'>
                          <span className='span-tier d-flex flex-column w-10'>{tier.type}</span>
                          <span className='tier-spans'>
                            Tier One:
                            <b>
                              {' '}
                              {' <='}
                              {tier.tier2Qty &&
                                tier.multiple &&
                                tier.tier2Qty! - tier.multiple!}{' '}
                              Pcs
                            </b>
                          </span>
                          <span className='tier-spans'>
                            Tier Two:
                            <b>
                              {' '}
                              {tier.tier2Qty} -{' '}
                              {tier.tier2Qty && tier.multiple && tier.tier3Qty! - tier.multiple!}{' '}
                              Pcs
                            </b>
                          </span>
                          <span className='tier-spans'>
                            Tier Three:
                            <b>
                              {' '}
                              {'>='} {tier.tier3Qty} Pcs
                            </b>
                          </span>
                        </div>
                      )}
                      {tier.tier1Qty != 0 && tier.tier2Qty != 0 && tier.tier3Qty == 0 && (
                        <div className='spans align-items-center'>
                          <span className='span-tier d-flex flex-column w-10'>{tier.type}</span>
                          <span className='tier-spans'>
                            Tier One:
                            <b>
                              {' '}
                              {'<='}
                              {tier.tier1Qty && tier.tier1Qty} Pcs
                            </b>
                          </span>
                          <span className='tier-spans'>
                            Tier Two:
                            <b>
                              {' '}
                              {'>='} {tier.tier2Qty && tier.tier2Qty} Pcs
                            </b>
                          </span>
                          <span className='tier-spans'>
                            Tier Three:
                            <b> N/A</b>
                          </span>
                        </div>
                      )}
                      {tier.tier1Qty != 0 && tier.tier2Qty == 0 && tier.tier3Qty == 0 && (
                        <div className='spans align-items-center'>
                          <span className='span-tier d-flex flex-column w-10'>{tier.type}</span>
                          <span className='tier-spans'>
                            Crate:
                            <b> {tier.tier1Qty} Pcs</b>
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
            {!order.isTierQuantityBased && (
              <div>
                <div>Variety pricing is based on quantity ordered:</div>
                {order.orderTiers.map((tier) => (
                  <div key={tier.id} style={{ display: 'flex' }}>
                    {tier.multiple &&
                      tier.multiple > 1 &&
                      tier.tier2Qty != 0 &&
                      tier.tier3Qty != 0 && (
                        <div className='spans align-items-center'>
                          <span className='span-tier d-flex flex-column w-10'>{tier.type}</span>
                          <span className='span-tier d-flex flex-column'>
                            Tier One:
                            <b> $ ea @ {tier.tier1Qty} pcs</b>
                          </span>
                          <span className='span-tier d-flex flex-column'>
                            Tier Two:
                            <b>
                              {' '}
                              $ ea @ {tier.tier2Qty} -{' '}
                              {tier.tier3Qty && tier.tier3Qty - tier.multiple} pcs
                            </b>
                          </span>
                          <span className='span-tier d-flex flex-column'>
                            Tier Three:
                            <b>
                              {' '}
                              $ ea @ {'>='} {tier.tier3Qty} pcs
                            </b>
                          </span>
                        </div>
                      )}
                    {tier.multiple &&
                      tier.multiple > 1 &&
                      tier.tier2Qty != 0 &&
                      tier.tier3Qty == 0 && (
                        <div className='spans align-items-center'>
                          <span className='span-tier d-flex flex-column w-10'>{tier.type}</span>
                          <span className='span-tier d-flex flex-column'>
                            Tier One:
                            <b> $ ea @ {tier.tier1Qty} pcs</b>
                          </span>
                          <span className='span-tier d-flex flex-column'>
                            Tier Two:
                            <b>
                              {' '}
                              $ ea @ {'>='} {tier.tier2Qty} pcs
                            </b>
                          </span>
                        </div>
                      )}
                    {tier.multiple &&
                      tier.multiple > 1 &&
                      tier.tier2Qty == 0 &&
                      tier.tier3Qty == 0 && (
                        <div className='spans align-items-center'>
                          <span className='span-tier d-flex flex-column w-10'>{tier.type}</span>
                          <span className='span-tier d-flex flex-column'>
                            Crate:
                            <b> $ ea @ {tier.tier1Qty} pcs</b>
                          </span>
                        </div>
                      )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {order.isDiscountAmountBased && (
            <div className='column form-card flex-grow-1'>
              {order.isDiscountAmountBased &&
                order.discounts.map((discount) => (
                  <div key={discount.id}>
                    <span>
                      <b>{discount.discValue}%</b> Discount for orders over{' '}
                      <b>${discount.minQty.toFixed(2)}</b>
                    </span>
                  </div>
                ))}
            </div>
          )}
          {!order.isDiscountAmountBased && order.discounts.length > 0 && (
            <div className='column form-card flex-grow-1'>
              {!order.isDiscountAmountBased &&
                order.discounts.map((discount) => (
                  <div key={discount.id}>
                    <span>
                      <b>{discount.discValue}%</b> Discount for orders over{' '}
                      <b>{discount.minQty} pieces</b>
                    </span>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className='d-flex gap-1 py-2'>
          {/* <div className='column form-card flex-grow-1 shipping-content'> */}
            {order.deposit !== 0 && (
              <div className='deposit-content column form-card flex-grow-1 shipping-content'>
                A <b>{order.deposit}%</b> deposit is needed to book your order. This is a non-refundable
                restocking fee in the event of order cancellation, due within 14 days after order date.
              </div>
            )}
            <div className='deposit-content column form-card flex-grow-1 shipping-content'>
              Shipping begins Week <b>{Math.round(order.shippingWeek / 10000)}</b> -{' '}
              <b>{Math.round(order.shippingWeek % 10000)}</b>
            </div>
            <div  className='deposit-content column form-card flex-grow-1 shipping-content' >
              <span dangerouslySetInnerHTML={{ __html: order.notes }}></span>
            </div>
          {/* </div> */}
        </div>

        <div>
          <span>
            {
              
              order.files.map((file) => (
                
                <Tag key={file.title}>
                  <span key={file.title}>
                    <a
                      href={file.url.indexOf('http') > -1 ? file.url : `https://${file.url}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {file.title} <LinkOutlined /> &emsp;
                    </a>
                  </span>
                </Tag>
                
              ))
            }
          </span>
        </div>
      </div>

      <div>
        <OrderTable
          products={data.products}
          tiers={order.orderTiers}
          prodId={order.id}
          isTierQtyBased={order.isTierQuantityBased!}
          isTierSameForAll={order.isTierSameForAll!}
        />
      </div>
    </div>
  );
}
StepOne.propTypes = {
  order: propTypes.any,
  tiers: propTypes.any,
  isTierQtyBased: propTypes.any,
  prodId: propTypes.any,
  isTierSameForAll: propTypes.any,
};

export default StepOne;
