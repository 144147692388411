import React, { useEffect } from 'react';
import { useTheme } from '../context/theme-context';
import { Avatar, Button, Dropdown, MenuProps, Space, Tooltip } from 'antd';
import {
  FormOutlined,
  LogoutOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { UserData } from '../schema/user';
import { decryptString } from '../common/encryption';
import { Auth } from 'aws-amplify';
import { NavLink, useNavigate } from 'react-router-dom';
import logoImg from '../assets/images/unicornblooms-img.png';

function Header() {
  const { toggle, setToggle } = useTheme();
  const [user, setUser] = React.useState<UserData>();
  const loggedUserDataStr = localStorage.getItem('loggedUserData');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        const attributes = authenticatedUser.attributes;
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedUserDataStr) {
      const parsedData = JSON.parse(decryptString(loggedUserDataStr));
      setUser(parsedData);
    }
    fetchUserAttributes();
  }, [loggedUserDataStr]);

  //Sign Out
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      navigate('/');
      //setUserAttributes(null); // Reset user attributes after sign out
    } catch (error) {
      // Handle error signing out
      console.log(error);
    }
  };

  const handleClick = (event: any, link: any) => {
    event.preventDefault();
    window.location.href = link;
  };

  useEffect(() => {
    if (!toggle && user && user.role !== 'User') {
      document.body.classList.add('customer-body-pd');
    } else {
      document.body.classList.remove('customer-body-pd');
    }

    // Clean up the effect when the component unmounts
    return () => {
      document.body.classList.remove('customer-body-pd');
    };
  }, [toggle, user]);

  const toggleSidebar = () => {
    setToggle(!toggle);
  };

  let items: MenuProps['items'];

  if (user && user.role === 'User') {
    items = [
      {
        label: (
          <a className='fw-600' onClick={(event: any) => handleClick(event, '/order-now')}>
            Order Now
          </a>
        ),
        key: '0',
        icon: <ShoppingCartOutlined style={{ fontSize: '16px' }} />,
      },
      {
        label: (
          <a className='fw-600' onClick={(event: any) => handleClick(event, '/personal-info')}>
            User Info
          </a>
        ),
        key: '1',
        icon: <FormOutlined style={{ fontSize: '16px' }} />,
      },
      {
        label: (
          <a className='fw-600' onClick={(event: any) => handleClick(event, '/user-setting')}>
            User Setting
          </a>
        ),
        key: '3',
        icon: <SettingOutlined style={{ fontSize: '16px' }} />,
      },
      {
        type: 'divider',
      },
      {
        label: (
          <a className='fw-600' onClick={handleSignOut}>
            Sign Out
          </a>
        ),
        key: '4',
        icon: <LogoutOutlined onClick={handleSignOut} style={{ fontSize: '16px' }} />,
        style: { background: '#f0cab7' },
      },
    ];
  } else {
    items = [
      {
        label: (
          <a className='fw-600' onClick={(event: any) => handleClick(event, '/user-setting')}>
            User Setting
          </a>
        ),
        key: '3',
        icon: <SettingOutlined style={{ fontSize: '16px' }} />,
      },
      {
        type: 'divider',
      },
      {
        label: (
          <a className='fw-600' onClick={handleSignOut}>
            Sign Out
          </a>
        ),
        key: '4',
        icon: <LogoutOutlined onClick={handleSignOut} style={{ fontSize: '16px' }} />,
        style: { background: '#f0cab7' },
      },
    ];
  }

  return (
    <header
      className={`${toggle ? 'body-pd' : ''} header primary-bg 
    `}
      id='header'
    >
      {user && user.role !== 'User' && (
        <div className='header_toggle'>
          {' '}
          <Tooltip title={'Expand/Collapse'}>
            <i className='bi bi-list' onClick={toggleSidebar}></i>
          </Tooltip>
        </div>
      )}

      {user && user.role == 'User' && <span></span>}
      <span className='fw-600 d-flex gap-2 align-items-center'>
        <Avatar src={logoImg} size='large' />
        <b>Unicorn Blooms</b>
      </span>
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button onClick={(e) => e.preventDefault()} shape='circle' icon={<UserOutlined />} />
      </Dropdown>
    </header>
  );
}

export default Header;
