import React, { useState, PropsWithChildren } from 'react';
import { PastOrder, Product } from '../schema/order';
import { UserData } from '../schema/user';
import { createCtx } from './context-helper';

type StepperContext = {
  stepOneData: Product[] | [];
  setStepOneData: React.Dispatch<React.SetStateAction<Product[]>>;

  stepTwoBillingAddress: UserData;
  setStepTwoBillingAddress: React.Dispatch<React.SetStateAction<UserData>>;

  stepTwoShippingAddress: UserData;
  setStepTwoShippingAddress: React.Dispatch<React.SetStateAction<UserData>>;

  shippingAddressDetails: UserData;
  setShippingAddressDetails: React.Dispatch<React.SetStateAction<UserData>>;

  sameBillingAddress: boolean;
  setClear: React.Dispatch<React.SetStateAction<boolean>>;

  stepTwoShippingWeek: string;
  setStepTwoShippingWeek: React.Dispatch<React.SetStateAction<string>>;

  stepTwoShippingYear: string;
  setStepTwoShippingYear: React.Dispatch<React.SetStateAction<string>>;

  stepTwoDeliveryNotes: string;
  setStepTwoDeliveryNotes: React.Dispatch<React.SetStateAction<string>>;

  stepOneRes: any;
  setStepOneRes: React.Dispatch<React.SetStateAction<string>>;

  stepTwoRes: any;
  setStepTwoRes: React.Dispatch<React.SetStateAction<string>>;

  termsConditions: boolean;
  setTermsConditions: React.Dispatch<React.SetStateAction<boolean>>;

  shippingSameAsBilling: boolean;
  setShippingSameAsBilling: React.Dispatch<React.SetStateAction<boolean>>;

  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;

  pastOrders: PastOrder[];
  setPastOrders: React.Dispatch<React.SetStateAction<PastOrder[]>>;

  isOrderExisting: boolean;
  setIsOrderExisting: React.Dispatch<React.SetStateAction<boolean>>;

  showShipWeekPopUp: boolean;
  setShowShipWeekPopUp: React.Dispatch<React.SetStateAction<boolean>>;

  addressIndex: number;
  setAddressIndex: React.Dispatch<React.SetStateAction<number>>;

  showShipAddPopUp: boolean;
  setShowShipAddPopUp: React.Dispatch<React.SetStateAction<boolean>>;

  showFinalModalPopUp: boolean;
  setShowFinalModalPopUp: React.Dispatch<React.SetStateAction<boolean>>;

  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;

  subTotalAmount: number;
  setSubTotalAmount: React.Dispatch<React.SetStateAction<number>>;
};

const [useStepper, Provider] = createCtx<StepperContext>();

function StepperProvider({ children }: PropsWithChildren<object>) {
  const [stepOneData, setStepOneData] = useState<Product[]>([]);
  const [stepTwoBillingAddress, setStepTwoBillingAddress] = useState<UserData>({
    name: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    userCreateDate: '',
    addressLine2: '',
    taxNo: '',
    province: '',
    userLastModifiedDate: '',
    enabled: false,
    userStatus: '',
    postalCode: '',
    tax: '',
    city: '',
  });
  const [stepTwoShippingAddress, setStepTwoShippingAddress] = useState<UserData>({
    name: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    userCreateDate: '',
    addressLine2: '',
    taxNo: '',
    province: '',
    userLastModifiedDate: '',
    enabled: false,
    userStatus: '',
    postalCode: '',
    tax: '',
    city: '',
  });
  const [shippingAddressDetails, setShippingAddressDetails] = useState<UserData>({
    name: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    userCreateDate: '',
    addressLine2: '',
    taxNo: '',
    province: '',
    userLastModifiedDate: '',
    enabled: false,
    userStatus: '',
    postalCode: '',
    tax: '',
    city: '',
  });
  const [stepTwoShippingWeek, setStepTwoShippingWeek] = useState<string>('');
  const [stepTwoShippingYear, setStepTwoShippingYear] = useState<string>('');
  const [stepTwoDeliveryNotes, setStepTwoDeliveryNotes] = useState<string>('');
  const [sameBillingAddress, setClear] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [subTotalAmount, setSubTotalAmount] = useState<number>(0);

  const [stepTwoRes, setStepTwoRes] = useState<any>({
    billableAmt: 0,
    deposit: 0,
    depositAmt: 0,
    dis: 0,
    disAmt: 0,
    products: [
      {
        id: 0,
        type: '',
        quantity: 0,
        orderedQuantity: 0
      },
    ],
    totalGoodsAmt: 0,
  });
  const [stepOneRes, setStepOneRes] = useState<any>({
    billableAmt: 0,
    deposit: 0,
    depositAmt: 0,
    dis: 0,
    disAmt: 0,
    products: [
      {
        id: 0,
        type: '',
        quantity: 0,
        orderedQuantity: 0
      },
    ],
    totalGoodsAmt: 0,
  });
  const [termsConditions, setTermsConditions] = useState<boolean>(false);
  const [shippingSameAsBilling, setShippingSameAsBilling] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isOrderExisting, setIsOrderExisting] = useState<boolean>(false);
  const [showShipWeekPopUp, setShowShipWeekPopUp] = useState<boolean>(false);
  const [showShipAddPopUp, setShowShipAddPopUp] = useState<boolean>(false);
  const [addressIndex, setAddressIndex] = useState<number>(0);
  const [showFinalModalPopUp, setShowFinalModalPopUp] = useState<boolean>(false);

  const [pastOrders, setPastOrders] = useState<any>({
    orderId: 0,
    shipWeek: 0,
    address: {
      id: 0,
      shippingWeek: 0,
      shippingEmail: '',
      shippingAddressName: '',
      shippingAddressBusiness: '',
      shippingAddressPhone: '',
      shippingAddressStreetAddress: '',
      shippingAddressAdddressLine2: '',
      shippingAddressCity: '',
      shippingAddressPostalCode: '',
      shippingAddressProvince: '',
      shippingAmount: 0,
      shippingLabelPrintedAt: '',
      shippedAt: '',
      trackingId: '',
      trackingWebsite: '',
    },
  });

  const value = {
    stepOneData,
    setStepOneData,
    stepTwoBillingAddress,
    setStepTwoBillingAddress,
    stepTwoShippingAddress,
    setStepTwoShippingAddress,
    shippingAddressDetails,
    setShippingAddressDetails,
    stepTwoShippingWeek,
    setStepTwoShippingWeek,
    stepTwoShippingYear,
    setStepTwoShippingYear,
    stepTwoDeliveryNotes,
    setStepTwoDeliveryNotes,
    sameBillingAddress,
    setClear,
    stepOneRes,
    setStepOneRes,
    stepTwoRes,
    setStepTwoRes,
    termsConditions,
    setTermsConditions,
    shippingSameAsBilling,
    setShippingSameAsBilling,
    currentStep,
    setCurrentStep,
    pastOrders,
    setPastOrders,
    isOrderExisting,
    setIsOrderExisting,
    showShipWeekPopUp,
    setShowShipWeekPopUp,
    showShipAddPopUp,
    setShowShipAddPopUp,
    addressIndex,
    setAddressIndex,
    showFinalModalPopUp,
    setShowFinalModalPopUp,
    title,
    setTitle,
    subTotalAmount,
    setSubTotalAmount,
  };

  return <Provider value={value}>{children}</Provider>;
}

export { StepperProvider, useStepper };
