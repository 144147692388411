import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../config/axios';
import {
  cancelOrder,
  emailInvoice,
  orderDetails,
  orderReminder,
  sendAcknowledgement,
  status,
  userNotes,
} from '../../../config/api-routes';
import { OrderDetail } from '../../../schema/order';
import TextArea from 'antd/es/input/TextArea';
import OrderDetailsTable from './orderDetailsTable';
import propTypes from 'prop-types';
import OrderDetailsPayment from './orderDetailsPayment';
import {
  Breadcrumb,
  Button,
  Card,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Tag,
  message,
} from 'antd';
import moment from 'moment';
import Invoice from './invoice';
import ReactDOMServer from 'react-dom/server';
import PickList from './pickList';
import { CloseCircleOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import AddNewProduct from '../Modals/addNewProduct';
import { useForm } from 'antd/es/form/Form';

function OrderDetails() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formRef = React.createRef<FormInstance>();
  const customerForm = React.createRef<FormInstance>();
  const [popUpForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState<boolean>(false);
  const [isShippedModalVisible, setIsShippedModalVisible] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [cancelOrderVisible, setCancelOrderVisible] = useState<boolean>(false);
  const [deleteOrderVisible, setDeleteOrderVisible] = useState<boolean>(false);
  const [isAcknowledgementModalVisible, setIsAcknowledgementModalVisible] =
    useState<boolean>(false);

  const [generatInvoiceLoader, setGenerateInvoiceLoader] = useState(false);
  const [sendAcknowledgementLoader, setSendAcknowledgementLoader] = useState(false);
  const [emailInvoiceLoader, setEmailInvoiceLoader] = useState(false);
  const [printPLLoader, setPrintPLLoader] = useState(false);
  const [markPackedLoader, setMarkPackedLoader] = useState(false);
  const [shipLabelLoader, setShipLabelLoader] = useState(false);
  const [markShippedLoader, setMarkShippedLoader] = useState(false);
  const [sendReminderLoader, setSendReminderLoader] = useState(false);
  const [addNewProductmodalVisible, setAddNewProductmodalVisible] = useState(false);
  const [shipWeekChangeLoader, setShipWeekChangeLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [orderLastUpdated, setOrderLastUpdated] = useState<string>('');
  const [shippingAmount, SetShippingAmount] = useState(0);
  const [selectedShipWeek, setSelectedShipWeek] = useState('');
  const [isShippingWeekModalVisible, setIsShippingWeekModalVisible] = useState<boolean>(false);

  const [odrDetails, setOrderDetails] = useState<OrderDetail>({
    id: 0,
    orderFormId: 0,
    discount: 0,
    goodsTaxRate: 0,
    isInvoiced: false,
    notes: '',
    deposit: 0,
    orderNotes: '',
    userDetails: {
      id: 0,
      shippingWeek: 0,
      shippingEmail: '',
      shippingAddressName: '',
      shippingAddressBusiness: '',
      shippingAddressPhone: '',
      shippingAddressStreetAddress: '',
      shippingAddressAdddressLine2: '',
      shippingAddressCity: '',
      shippingAddressPostalCode: '',
      shippingAddressProvince: '',
      shippingAmount: 0,
      shippingLabelPrintedAt: '',
      shippedAt: '',
      trackingId: '',
      trackingWebsite: '',
      userNotes: '',
    },
    preDiscAmt: 0,
    credits: 0,
    // updatedAt:'',
    specialDiscount: 0,
    paymentStatus: '',
    plPrintedAt: '',
    plPrintedCount: 0,
    packedAt: '',
    primaryOrder: 0,
    showReminderButton: false,
    amountPaid: 0,
    fallOrders: [],
    isCancelled: false,
    orderNumber: '',
    revisedOrder: false,
    allowHeated: false,
    products: [
      {
        id: 0,
        name: '',
        size: '',
        type: '',
        subType: '',
        tier1Price: 0,
        tier2Price: 0,
        tier3Price: 0,
        totalStock: 0,
        totalOrdered: 0,
        scrap: 0,
        bufferStock: 0,
        totalScrap: 0,
        imageUrl: '',
        notes: '',
        quantity: 0,
        productId: 0,
        selectedQuantity: 0,
        totalPrice: 0,
        pricePerPiece: 0,
        orderedQuantity: 0,
        productLimit: 0,
      },
    ],
    paymentHistory: [],
    invoiceDetails: {
      subTotal: 0,
      creditAmount: 0,
      discount: 0,
      discountAmount: 0,
      specialDiscountAmount: 0,
      shippingAmount: 0,
      preTaxAmount: 0,
      tax: {
        gst: {
          taxType: '',
          rate: 0,
          amount: 0,
        },
        pstqst: {
          taxType: '',
          rate: 0,
          amount: 0,
        },
        hst: {
          taxType: '',
          rate: 0,
          amount: 0,
        },
        totalTax: {
          rate: 0,
          amount: 0,
        },
      },
      taxAmount: 0,
      grandTotal: 0,
      status: '',
      deposit: 0,
      depositAmount: 0,
      remainingAmount: 0,
      dueDepositAmount: 0,
    },
    shippingDetails: {
      id: 0,
      shippingWeek: 0,
      shippingEmail: '',
      shippingAddressName: '',
      shippingAddressBusiness: '',
      shippingAddressPhone: '',
      shippingAddressStreetAddress: '',
      shippingAddressAdddressLine2: '',
      shippingAddressCity: '',
      shippingAddressPostalCode: '',
      shippingAddressProvince: '',
      shippingAmount: 0,
      shippingLabelPrintedAt: '',
      shippedAt: '',
      trackingId: '',
      trackingWebsite: '',
      userNotes: '',
    },
  });
  const [form] = useForm();
  const weeks: JSX.Element[] = [];

  interface WeekData {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
  }

  const getOrderString = () => {
    const orderString = odrDetails.orderNumber.match(/^(\w{2})/);
    let string = '';

    if (orderString && orderString[1] === 'PN') {
      string = 'Peony';
    } else if (orderString && orderString[1] === 'OS') {
      string = 'Over Stock';
    } else if (orderString && orderString[1] === 'FB') {
      string = 'Fall Bulbs';
    } else if (orderString && orderString[1] === 'IR') {
      string = 'Italian Ranunculus';
    } else if (orderString && orderString[1] === 'DH') {
      string = 'Dahlia';
    }
    return string;
  };

  const shipping = odrDetails.shippingDetails;

  const renderSwitch = (paymentStatus: string) => {
    switch (paymentStatus) {
      case 'CANCELLED':
        return (
          <span>
            {
              <Tag color='#42434b' style={{ color: 'white' }}>
                {paymentStatus}
              </Tag>
            }
          </span>
        );
        break;
      case 'UNPAID':
        return <span>{<Tag color='#BA704F'>{paymentStatus}</Tag>}</span>;
        break;
      case 'PAID':
        return <span>{<Tag color='#7A9D54'>{paymentStatus}</Tag>}</span>;
        break;
      case 'OVERPAID':
        return <span>{<Tag color='#75C2F6'>{paymentStatus}</Tag>}</span>;
        break;
      case 'PARTIAL':
        return (
          <span>
            {
              <Tag color='#FDFFAE' style={{ color: 'black' }}>
                {paymentStatus}
              </Tag>
            }
          </span>
        );
        break;
      case 'DEPOSIT PAID':
        return <span>{<Tag color='gold'>{paymentStatus}</Tag>}</span>;
        break;
      case 'NONE':
        return <span>{<Tag color='green'>{paymentStatus}</Tag>}</span>;
        break;
      default:
        return <span></span>;
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = async () => {
    setLoading(true);
    await api.get(orderDetails + '/' + id).then((res: any) => {
      setOrderLastUpdated(res.updatedAt);
      setOrderDetails(res);
      SetShippingAmount(res.shippingDetails.shippingAmount);
      setLoading(false);
    });
  };
  const { id } = useParams<{ id: string }>();

  const handleChange = () => {
    setIsChanged(true);
  };

  const handleBlur = () => {
    if (isChanged) {
      formRef.current?.submit();
      setIsChanged(false);
    }
  };

  const onFinish = async (values: any) => {
    const payload = values;
    if (payload?.orderNotes.trim() != '') {
      await api
        .put<never, any>(orderDetails + `/${id}`, payload)
        .then((res: any) => {
          if (res) {
            message.success(res.message);
          }
        })
        .catch((error: any) => {
          message.error(error?.response?.data?.message);
        });
    }
  };

  const updateStatus = async (paramPayload: any) => {
    const payload = { ...paramPayload, shippingId: shipping.id };

    await api
      .put<never, any>(status + `/${id}`, payload)
      .then((res: any) => {
        if (res) {
          setMarkShippedLoader(false);
          setGenerateInvoiceLoader(false);
          setShipLabelLoader(false);
          setMarkPackedLoader(false);
          message.success(res.message);
        }
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setGenerateInvoiceLoader(false);
        setMarkShippedLoader(false);
        setShipLabelLoader(false);
        setMarkPackedLoader(false);
      });
    getOrderDetails();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  // const handleOk = () => {
  //   popUpForm
  //     .validateFields()
  //     .then((values: any) => {
  //       setShipLabelLoader(true);
  //       updateStatus({
  //         shipLabel: true,
  //         trackingWebsite: values.trackingUrl,
  //       });
  //       setIsModalVisible(false);
  //       popUpForm.resetFields();
  //     })
  //     .catch(() => {
  //       return false;
  //     });
  // };


  const handleOk = () => {
    setShipLabelLoader(true);
    updateStatus({
      shipLabel: true,
      trackingWebsite: popUpForm.getFieldValue('trackingUrl'),
    });
    setIsModalVisible(false);
    popUpForm.resetFields();
  };


  const handleCancel = () => {
    popUpForm.resetFields();
    setIsModalVisible(false);
  };

  const handleInvoiceOk = () => {
    setGenerateInvoiceLoader(true);
    updateStatus({ generateInvoice: true });
    setIsInvoiceModalVisible(false);
  };

  const handleAcknowledgementOk = async () => {
    setSendAcknowledgementLoader(true);
    await api
      .get(sendAcknowledgement + '/' + odrDetails.id)
      .then((res: any) => {
        setSendAcknowledgementLoader(false);
        setIsAcknowledgementModalVisible(false);
        message.success(res.message);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
      });
  };

  const handleAcknowledgementCancel = () => {
    setIsAcknowledgementModalVisible(false);
  };

  const handleInvoiceCancel = () => {
    setIsInvoiceModalVisible(false);
  };

  const handleChangeShippingWeekCancel = () => {
    setIsShippingWeekModalVisible(false);
  };

  const handleChangeShippingWeekOk = () => {
    setShipWeekChangeLoader(true);
    api
      .put<never, any>(orderDetails + `/${id}`, { shipWeek: selectedShipWeek })
      .then((res: any) => {
        if (res) {
          getOrderDetails();
          setShipWeekChangeLoader(false);
          setIsShippingWeekModalVisible(false);
          message.success(res.message);
        }
      })
      .catch((error: any) => {
        setShipWeekChangeLoader(false);
        message.error(error?.response?.data?.message);
      });
  };

  const generateInvoice = () => {
    if (shippingAmount && shippingAmount > 0) {
      updateStatus({ generateInvoice: true });
    } else {
      setIsInvoiceModalVisible(true);
    }
  };

  const onClickAcknowledgement = () => {
    setIsAcknowledgementModalVisible(true);
  };

  const handleShippedOk = () => {
    setMarkShippedLoader(true);
    updateStatus({ shipped: true });
    setIsShippedModalVisible(false);
  };

  const handleShippedCancel = () => {
    setIsShippedModalVisible(false);
  };

  const markedShipped = () => {
    if (
      odrDetails.invoiceDetails.status === 'PAID' ||
      odrDetails.invoiceDetails.status === 'OVERPAID'
    ) {
      setMarkShippedLoader(true);
      updateStatus({ shipped: true });
    } else {
      setIsShippedModalVisible(true);
    }
  };

  const printInvoice = () => {
    const mywindow = window.open('', 'PRINT', 'height=1200,width=1200');

    const depositDates: any = [];
    const depositAmounts: any = [];
    const paymentDates: any = [];
    const paymentAmounts: any = [];

    for (const paymentDetails of odrDetails.paymentHistory) {
      if (paymentDetails.paymentType.toLowerCase() == 'deposit') {
        if (paymentDetails.paymentMethod != 'CreditRevert') {
          depositDates.push(moment(paymentDetails.dateOfPayment).format('LL'));
          depositAmounts.push(paymentDetails.amount.toFixed(2));
        }
      } else {
        if (paymentDetails.paymentMethod != 'CreditRevert') {
          paymentDates.push(moment(paymentDetails.dateOfPayment).format('LL'));
          paymentAmounts.push(paymentDetails.amount.toFixed(2));
        }
      }
    }

    const getInvoiceString = ReactDOMServer.renderToStaticMarkup(
      <Invoice
        productDetails={odrDetails.products}
        invoiceDetails={odrDetails.invoiceDetails}
        shippingDetails={odrDetails.shippingDetails}
        orderNumber={odrDetails.orderNumber}
        getOrderString={() => getOrderString()}
        depositDates={depositDates}
        depositAmounts={depositAmounts}
        paymentDates={paymentDates}
        paymentAmounts={paymentAmounts}
      />,
    );

    if (mywindow) {
      mywindow.document.write(
        '<html> <head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"> <style>@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");body,select,option {font-family: "Montserrat", sans-serif;}table {width: 90%;margin-left: 5%;border-collapse: collapse;}th,td {padding: 0.5%;text-align: center;border: 1px solid #CCC;}#header {padding-top: 2%;padding-bottom: 2%;margin-bottom: 1.5%;/* background:#f7ece2; */}#titlewrapper {padding-top: 1%;padding-bottom: 1%;margin-left: 25%;width: 50%;text-align: center;border: 2px solid black;}#title {font-weight: 300;font-size: 1.3em;}#date {font-weight: 300;font-size: 1em;}.align-left {text-align: left;}    .align-right {text-align: right;} .width-50 {width: 50%;} </style><title>Invoice</title></head>',
      );

      mywindow.document.write(getInvoiceString);

      mywindow.document.write(
        '<script>var today = new Date();var year = today.getFullYear();var month = today.getMonth();month = month + 1;var day = today.getDate();var newdate = day + "-" + month + "-" + year; </script> </html>',
      );
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      // mywindow.close();
    }
  };

  const printPL = () => {
    const mywindow = window.open('', 'PRINT', 'height=1200,width=1200');
    const getPickListString = ReactDOMServer.renderToStaticMarkup(
      <PickList
        productDetails={odrDetails.products}
        shippingDetails={odrDetails.shippingDetails}
        deliveryNotes={odrDetails.notes}
        fallOrders={odrDetails?.fallOrders}
        isHeated={odrDetails?.allowHeated}
      />,
    );

    if (mywindow) {
      mywindow.document.write(
        '<html> <head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"><style>@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");body,select,option {font-family: "Montserrat", sans-serif;}table {width: 90%;margin-left: 5%;border-collapse: collapse;}th,td {padding: 0.5%;text-align: center;border: 1px solid #CCC;}#header {padding-top: 2%;padding-bottom: 2%;margin-bottom: 1.5%;/* background:#f7ece2; */}#titlewrapper {padding-top: 1%;padding-bottom: 1%;margin-left: 25%;width: 50%;text-align: center;border: 2px solid black;}#title {font-weight: 300;font-size: 1.3em;}#date {font-weight: 300;font-size: 1em;}.align-left {text-align: left;}.align-right {text-align: right;} .width-50 {width: 50%;} .fall-orders{width:90%;margin-left:5%;text-align:center;} </style><title>Pick List</title></head>',
      );
      mywindow.document.write(getPickListString);

      mywindow.document.write(
        '<script>var today = new Date();var year = today.getFullYear();var month = today.getMonth();month = month + 1;var day = today.getDate();var newdate = day + "-" + month + "-" + year; </script> </html>',
      );

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      // mywindow.close();
    }
  };

  const printPickList = () => {
    const payload = { printLabel: true, shippingId: shipping.id };
    setPrintPLLoader(true);
    api
      .put<never, any>(status + `/${id}`, payload)
      .then((res: any) => {
        if (res) {
          setPrintPLLoader(false);
          message.success(res.message);
          getOrderDetails();
          printPL();
        }
      })
      .catch((error: any) => {
        setPrintPLLoader(false);
        message.error(error?.response?.data?.message);
      });
  };

  const handleCancelOrder = () => {
    setCancelOrderVisible(true);
  };

  const onCancelOrderCancel = () => {
    setCancelOrderVisible(false);
  };

  const makeCancelled = () => {
    setLoader(true);

    api
      .put<never, any>(cancelOrder + `/${odrDetails.id}`)
      .then((res: any) => {
        setLoader(false);
        message.success(res.message);
        getOrderDetails();
        setCancelOrderVisible(false);
      })
      .catch((error) => {
        setLoader(false);
        message.error(error?.response?.data?.message);
        setCancelOrderVisible(false);
      });
  };

  const sendEmailInvoice = () => {
    setEmailInvoiceLoader(true);
    api
      .get(`${emailInvoice}/${id}`)
      .then(() => {
        setEmailInvoiceLoader(false);
        message.success('Invoice mailed successfully');
      })
      .catch((error) => {
        setEmailInvoiceLoader(false);
        console.log(error);
        message.success('Unable to mail invoice');
      });
  };

  const sendReminderEmail = () => {
    setSendReminderLoader(true);
    api
      .put(`${orderReminder}/${id}`)
      .then(() => {
        setSendReminderLoader(false);
        message.success('Reminder sent successfully');
      })
      .catch((error) => {
        setSendReminderLoader(false);
        console.log(error);
        message.success('Unable to send reminder');
      });
  };

  const handlePacked = () => {
    setMarkPackedLoader(true);
    updateStatus({ packed: true });
  };

  const openNewProductModel = () => {
    setAddNewProductmodalVisible(true);
  };

  const closeNewProductModel = (res: string) => {
    setAddNewProductmodalVisible(false);
    if (typeof res === 'string' && res != '') {
      getOrderDetails();
    }
  };

  const handleChangeCustomerNotes = (event: any) => {
    handleChange();
    odrDetails.userDetails.userNotes = '';
    odrDetails.userDetails.userNotes = event.currentTarget.value;
  };

  const updateCustomerNote = (event: any) => {
    setSendReminderLoader(true);
    setIsChanged(false);
    const payload = { notes: odrDetails.userDetails.userNotes };

    if (isChanged) {
      api
        .put(`${userNotes}/${id}`, payload)
        .then(() => {
          setSendReminderLoader(false);
          message.success('Note updated successfully');
        })
        .catch((error) => {
          setSendReminderLoader(false);
          console.log(error);
          message.error('Unable update note');
        });
    }
  };

  const getWeekAndDates = (weekNumber: number, year: number): WeekData => {
    const firstDayOfYear = new Date(year, 0, 1);
    const dayOfWeek = firstDayOfYear.getDay();
    const daysToAdd = (weekNumber - 1) * 7 - dayOfWeek + 1;

    const startDate = new Date(year, 0, 1 + daysToAdd);
    const endDate = new Date(year, 0, 1 + daysToAdd + 6);

    return {
      weekNumber,
      startDate,
      endDate,
    };
  };

  const handleShipWeekChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue: any = event;
    setSelectedShipWeek(selectedValue);
    setIsShippingWeekModalVisible(true);
  };

  const startWeek = Math.round(odrDetails.startShippingWeek! / 10000);
  const endWeek = Math.round(odrDetails.endShippingWeek! / 10000);
  const startYear = odrDetails.startShippingWeek! % 10000;
  const endYear = odrDetails.endShippingWeek! % 10000;

  if (startYear !== endYear) {
    for (let i = startWeek; i <= 50; i++) {
      const { weekNumber, startDate, endDate } = getWeekAndDates(i, startYear);

      const optionValue = weekNumber.toString() + startDate.getFullYear().toString();
      const optionLabel = `Week ${weekNumber} (${moment(startDate).format('LL')} - ${moment(
        endDate,
      ).format('LL')})`;

      weeks.push(
        <Select.Option key={optionValue} value={optionValue}>
          {optionLabel}
        </Select.Option>,
      );
    }
    for (let i = 1; i <= endWeek; i++) {
      const { weekNumber, startDate, endDate } = getWeekAndDates(i, endYear);

      const optionValue = weekNumber.toString() + startDate.getFullYear().toString();
      const optionLabel = `Week ${weekNumber} (${moment(startDate).format('LL')} - ${moment(
        endDate,
      ).format('LL')})`;

      weeks.push(
        <Select.Option key={optionValue} value={optionValue}>
          {optionLabel}
        </Select.Option>,
      );
    }
  } else {
    for (let i = startWeek; i <= endWeek; i++) {
      const { weekNumber, startDate, endDate } = getWeekAndDates(i, startYear);

      const optionValue = weekNumber.toString() + startDate.getFullYear().toString();
      const optionLabel = `Week ${weekNumber} (${moment(startDate).format('LL')} - ${moment(
        endDate,
      ).format('LL')})`;

      weeks.push(
        <Select.Option key={optionValue} value={optionValue}>
          {optionLabel}
        </Select.Option>,
      );
    }
  }

  form.setFieldsValue({ shippingWeek: odrDetails.shippingDetails.shippingWeek.toString() });

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
  };

  const handleDeleteOrder = () => {
    setDeleteOrderVisible(true);
  };

  const onDeleteOrderCancel = () => {
    setDeleteOrderVisible(false);
  };

  const deleteOrder = () => {
    setDeleteLoader(true);
    api
      .delete<never, any>(orderDetails + `/${odrDetails.id}`)
      .then((res: any) => {
        setDeleteLoader(false);
        message.success(res.message);
        navigate(-1);
        setDeleteOrderVisible(false);
      })
      .catch((error) => {
        setDeleteLoader(false);
        message.error(error?.response?.data?.message);
        setDeleteOrderVisible(false);
      });
  };

  return (
    <div>
      <div className='px-4 pt-2'>
        <Breadcrumb
          items={[
            {
              title: (
                <span onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
                  Orders
                </span>
              ),
            },
            {
              title: 'Order Details',
            },
          ]}
        />
        <hr className='my-2' />
      </div>
      <div className='px-4 pt-2 d-flex justify-content-between'>
        <div className='d-flex flex-column'>
          <span>
            <span>{getOrderString()}</span>
            <span> Order for: </span>
            <span>
              <b>{shipping.shippingAddressBusiness}</b>
            </span>
          </span>
          <span className='pt-2'>
            <span> Order Number: </span>
            <span>
              <b>{odrDetails.orderNumber}</b>
            </span>
          </span>
          <span className='pt-2'>
            <span className='pt-2'>
              {odrDetails.allowHeated && (
                <b>{shipping.shippingAddressBusiness} has requested for heat packs </b>
              )}
            </span>
          </span>
        </div>

        <div className='align-self-center'>
          {!odrDetails.isCancelled && !odrDetails.shippingDetails.shippedAt && (
            <Button
              type='primary'
              className='d-flex align-items-center'
              onClick={openNewProductModel}
            >
              <PlusOutlined /> <span>Add New Product</span>
            </Button>
          )}
          {odrDetails.isCancelled && (
            <div className='d-flex flex-column'>
              <span style={{ color: 'red' }}>This order has been cancelled</span>
              <div className='d-flex justify-content-end'>
                {odrDetails.isCancelled && (
                  <Button
                    type='primary'
                    danger
                    onClick={handleDeleteOrder}
                    loading={deleteLoader}
                    className='d-flex align-items-center'
                  >
                    <DeleteOutlined />
                    Delete Order
                  </Button>
                )}
              </div>
              <Modal
                open={deleteOrderVisible}
                title={'Are you sure, you want to delete this order?'}
                onCancel={onDeleteOrderCancel}
                onOk={deleteOrder}
                destroyOnClose
                footer={[
                  <Button key='no' onClick={onDeleteOrderCancel}>
                    No
                  </Button>,
                  <Button
                    key='ok'
                    type='primary'
                    danger
                    onClick={deleteOrder}
                    loading={deleteLoader}
                  >
                    Yes
                  </Button>,
                ]}
              >
                Deleting the order will result in the permanent loss of the order details.{' '}
              </Modal>
            </div>
          )}
        </div>
      </div>
      <div className='px-3'>
        <div className='d-flex pb-3 gap-3'>
          <div className='flex-grow-1 d-flex flex-column gap-3'>
            <Card loading={loading}>
              <span className='secondary-color'>
                <b>Shiping Details</b>
              </span>
              <div></div>
              <div>
                <div className='flex-column'>
                  <p className='mb-0'>
                    {shipping.shippingAddressName ? `${shipping.shippingAddressName}` : ''}
                    <br />
                    {shipping.shippingAddressBusiness}
                  </p>
                  <p className='mb-0'>
                    {shipping.shippingAddressStreetAddress
                      ? `${shipping.shippingAddressStreetAddress}`
                      : ''}
                    <br />
                    {shipping.shippingAddressAdddressLine2 &&
                      (shipping.shippingAddressAdddressLine2
                        ? `${shipping.shippingAddressAdddressLine2}`
                        : '')}
                    {shipping.shippingAddressAdddressLine2 && <br />}
                    {shipping.shippingAddressCity}{' '}
                    {shipping.shippingAddressProvince ? `${shipping.shippingAddressProvince}` : ''}
                    <br />
                    {shipping.shippingAddressPostalCode}
                  </p>
                  <p className='mb-1'>
                    {shipping.shippingAddressPhone ? `+1${shipping.shippingAddressPhone}` : ''}
                    <br />
                    {shipping.shippingEmail}
                  </p>
                </div>
              </div>
            </Card>
            <Card loading={loading}>
              <span className='secondary-color'>
                <b>Order Notes</b>
              </span>
              <div>
                <Form ref={formRef} onFinish={onFinish}>
                  <Form.Item name='orderNotes'>
                    <TextArea
                      defaultValue={odrDetails.orderNotes}
                      placeholder='Add an Order Note'
                      rows={10}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </div>
          <Card className='flex-grow-1' style={{ maxWidth: '60%' }} loading={loading}>
            <div className='m-2'>
              <span className='secondary-color'>
                <b>Customer Notes</b>
              </span>

              <Form ref={customerForm} onFinish={handleChangeCustomerNotes}>
                <Form.Item name='customerNotes'>
                  <div className='d-flex justify-content-between'>
                    <TextArea
                      className='col-12'
                      defaultValue={odrDetails.userDetails.userNotes}
                      placeholder='Add an Note'
                      rows={10}
                      onBlur={updateCustomerNote}
                      onChange={handleChangeCustomerNotes}
                    />
                  </div>
                </Form.Item>
              </Form>
              {odrDetails.notes && (
                <div className='d-flex flex-column'>
                  <span className='secondary-color'>
                    <b>Delivery Notes</b>
                  </span>
                  <span>{odrDetails.notes}</span>
                </div>
              )}
              <div>
                <span className='d-flex align-items-center gap-3'>
                  <span>
                    <b>Requested Ship Week :</b>{' '}
                  </span>
                  <Form form={form}>
                    <Form.Item name='shippingWeek' className='mb-0'>
                      <Select
                        placeholder='Select a Ship Week'
                        disabled={
                          odrDetails.isCancelled || odrDetails.shippingDetails.shippedAt != ''
                        }
                        onChange={handleShipWeekChange}
                      >
                        {weeks}
                      </Select>
                    </Form.Item>
                  </Form>
                </span>
              </div>
              <div>
                <span>
                  <b>Payment Status :</b> {renderSwitch(odrDetails.invoiceDetails.status)}
                </span>
              </div>
              {odrDetails.plPrintedAt && (
                <div>
                  <div>
                    <span>
                      <b>Pick list printed at :</b> {moment(odrDetails.plPrintedAt).format('LL')}
                    </span>
                  </div>
                  <div>
                    {odrDetails.plPrintedCount > 0 && (
                      <span>
                        <b>Pick list printed for :</b> {odrDetails.plPrintedCount} times
                      </span>
                    )}
                  </div>
                  <div>
                    {!odrDetails.revisedOrder && odrDetails.plPrintedCount > 0 && (
                      <span>
                        <b>Pick list :</b>{' '}
                        <span className='secondary-color'>Pick List Printed</span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {odrDetails.plPrintedAt && (
                <div>
                  <div>
                    {odrDetails.revisedOrder && (
                      <span>
                        <b>Pick list :</b>{' '}
                        <span className='secondary-color'>Order Revised, Pick List Pending</span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {odrDetails.packedAt && (
                <div>
                  <span>
                    <b>Order packed at :</b> {moment(odrDetails.packedAt).format('LL')}
                  </span>
                </div>
              )}
              {odrDetails.shippingDetails.shippingLabelPrintedAt && (
                <div>
                  <span>
                    <b>Ship label printed at :</b>{' '}
                    {moment(odrDetails.shippingDetails.shippingLabelPrintedAt).format('LL')}
                  </span>
                </div>
              )}
              {odrDetails.shippingDetails.shippedAt && (
                <div>
                  <span>
                    <b>Shipped at :</b> {moment(odrDetails.shippingDetails.shippedAt).format('LL')}
                  </span>
                </div>
              )}
              <div>
                <span>
                  <b>Invoice Last Updated On:</b> { moment(orderLastUpdated).format('MMMM DD, YYYY  h:mm a') }
                </span>
              </div>
            </div>
          </Card>
        </div>
      </div>

      {!odrDetails.isCancelled && (
        <div className='px-3 d-flex justify-content-between'>
          <div className='d-flex gap-5'>
            {!odrDetails.isInvoiced && !loading && (
              <div>
                <Button
                  type='primary'
                  onClick={generateInvoice}
                  loading={generatInvoiceLoader || loading}
                >
                  Generate Invoice
                </Button>

                <Modal
                  title='Confirm'
                  open={isInvoiceModalVisible}
                  onOk={handleInvoiceOk}
                  onCancel={handleInvoiceCancel}
                  footer={[
                    <Button key='no' onClick={handleInvoiceCancel}>
                      No
                    </Button>,
                    <Button loading={loader} key='yes' type='primary' onClick={handleInvoiceOk}>
                      Yes
                    </Button>,
                  ]}
                >
                  Are you sure you want to generate invoice without shipping amount?
                </Modal>
              </div>
            )}

            {!loading && !odrDetails.isCancelled && (
              <div>
                <Button type='primary' onClick={printPickList} loading={printPLLoader || loading}>
                  Print PickList
                </Button>
              </div>
            )}

            {odrDetails.isInvoiced && !loading && (
              <div className='d-flex gap-5'>
                <div>
                  <Button
                    type='primary'
                    onClick={sendEmailInvoice}
                    loading={emailInvoiceLoader || loading}
                  >
                    Email Invoice
                  </Button>
                </div>
                <div>
                  <Button type='primary' onClick={printInvoice} loading={loading}>
                    Print Invoice
                  </Button>
                </div>
              </div>
            )}

            {odrDetails.plPrintedAt &&
              (odrDetails.packedAt === '' || odrDetails.packedAt === null) &&
              !loading && (
                <div>
                  <Button
                    type='primary'
                    onClick={handlePacked}
                    loading={markPackedLoader || loading}
                  >
                    Mark as Packed
                  </Button>
                </div>
              )}
            {odrDetails.packedAt &&
              (odrDetails.shippingDetails.shippingLabelPrintedAt === '' ||
                odrDetails.shippingDetails.shippingLabelPrintedAt === null) &&
              !loading && (
                <div>
                  <Button type='primary' onClick={showModal} loading={shipLabelLoader || loading}>
                    Ship Label Recieved
                  </Button>
                  <Modal
                    title='Enter tracking details'
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                      <Button key='no' onClick={handleCancel}>
                        Cancel
                      </Button>,
                      <Button key='yes' type='primary' onClick={handleOk}>
                        Ok
                      </Button>,
                    ]}
                  >
                    <Form form={popUpForm} {...layout}>
                      <Form.Item
                        name='trackingUrl'
                        label='Tracking URL'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Tracking URL',
                          },
                          { type: 'url' },
                        ]}
                      >
                        <Input placeholder='Enter Url' />
                      </Form.Item>
                    </Form>
                  </Modal>
                </div>
              )}
            {odrDetails.shippingDetails.shippingLabelPrintedAt &&
              (odrDetails.shippingDetails.shippedAt === '' ||
                odrDetails.shippingDetails.shippedAt === null) &&
              !loading && (
                <div>
                  <Button
                    type='primary'
                    onClick={markedShipped}
                    loading={markShippedLoader || loading}
                  >
                    Mark as Shipped
                  </Button>
                  <Modal
                    title='Confirm'
                    open={isShippedModalVisible}
                    onOk={handleShippedOk}
                    onCancel={handleShippedCancel}
                    footer={[
                      <Button key='no' onClick={handleShippedCancel}>
                        No
                      </Button>,
                      <Button key='yes' type='primary' onClick={handleShippedOk}>
                        Yes
                      </Button>,
                    ]}
                  >
                    Are you sure you want to ship this order without full payment?
                  </Modal>
                </div>
              )}
          </div>
          <div className='d-flex gap-4'>
            {!odrDetails.isInvoiced && !loading && (
              <div>
                <Button type='primary' onClick={onClickAcknowledgement}>
                  Order Acknowledgement
                </Button>

                <Modal
                  title='Confirm'
                  open={isAcknowledgementModalVisible}
                  onOk={handleAcknowledgementOk}
                  onCancel={handleAcknowledgementCancel}
                  footer={[
                    <Button key='no' onClick={handleAcknowledgementCancel}>
                      No
                    </Button>,
                    <Button
                      loading={sendAcknowledgementLoader}
                      key='yes'
                      type='primary'
                      onClick={handleAcknowledgementOk}
                    >
                      Yes
                    </Button>,
                  ]}
                >
                  Are you sure you want to send Acknowledgement?
                </Modal>
              </div>
            )}
            {!loading && (
              <div>
                <Button
                  type='primary'
                  danger
                  onClick={handleCancelOrder}
                  disabled={odrDetails.shippingDetails?.shippedAt != ''}
                  loading={loading}
                  className='d-flex align-items-center'
                >
                  <CloseCircleOutlined />
                  Cancel Order
                </Button>
                <Modal
                  open={cancelOrderVisible}
                  title={'Are you sure, you want to cancel this order?'}
                  onCancel={onCancelOrderCancel}
                  onOk={makeCancelled}
                  destroyOnClose
                  footer={[
                    <Button key='no' onClick={onCancelOrderCancel}>
                      No
                    </Button>,
                    <Button key='ok' type='primary' danger onClick={makeCancelled} loading={loader}>
                      Yes
                    </Button>,
                  ]}
                >
                  By canceling the order, you forfeit the ability to make any further changes to its
                  details.{' '}
                </Modal>
              </div>
            )}
          </div>
        </div>
      )}

      <div>
        <OrderDetailsTable
          products={odrDetails.products}
          invoice={odrDetails.invoiceDetails}
          id={odrDetails.id}
          getOrderDetails={getOrderDetails}
          loading={loading}
          isCancelled={odrDetails.isCancelled}
          isInvoiced={odrDetails.isInvoiced}
          shipped={odrDetails.shippingDetails?.shippedAt}
        />
      </div>
      <div className='px-3'></div>
      <OrderDetailsPayment
        isCancelled={odrDetails.isCancelled}
        id={odrDetails.id}
        getOrderDetails={getOrderDetails}
        loading={loading}
        amountPaid={odrDetails.amountPaid}
        remainingAmount={odrDetails?.invoiceDetails?.remainingAmount}
        showReminderButton={odrDetails.showReminderButton}
        sendReminderEmail={sendReminderEmail}
        sendReminderLoader={sendReminderLoader}
        invoiceDetails={odrDetails?.invoiceDetails}
      />

      {addNewProductmodalVisible && (
        <AddNewProduct
          orderFormId={odrDetails?.orderFormId}
          products={odrDetails?.products}
          orderId={odrDetails?.id}
          visible={addNewProductmodalVisible}
          onClose={closeNewProductModel}
        />
      )}

      <Modal
        title='Confirm'
        open={isShippingWeekModalVisible}
        onOk={handleChangeShippingWeekOk}
        onCancel={handleChangeShippingWeekCancel}
        footer={[
          <Button key='no' onClick={handleChangeShippingWeekCancel}>
            No
          </Button>,
          <Button
            loading={shipWeekChangeLoader}
            key='yes'
            type='primary'
            onClick={handleChangeShippingWeekOk}
          >
            Yes
          </Button>,
        ]}
      >
        Are you sure you want to change shipping week?
      </Modal>
    </div>
  );
}
OrderDetails.propTypes = {
  products: propTypes.any,
  invoice: propTypes.any,
  productDetails: propTypes.any,
  invoiceDetails: propTypes.any,
  shippingDetails: propTypes.any,
  deliveryNotes: propTypes.any,
  isCancelled: propTypes.any,
  fallOrder: propTypes.any,
  orderDetails: propTypes.any,
  orderNumber: propTypes.any,
  isInvoiced: propTypes.any,
  amountPaid: propTypes.any,
  remainingAmount: propTypes.any,
  sendReminderLoader: propTypes.any,
  setSendReminderLoader: propTypes.any,
  shipped: propTypes.any,
};

export default OrderDetails;
