import React from 'react';
import { useStepper } from '../../context/stepper-context';
import { Card } from 'antd';

const BillingAddressCard = () => {
  const { stepTwoBillingAddress } = useStepper();

  return (
    <Card className='h-100'>
      <span className='secondary-color'>
        <b>Shipping Details</b>
      </span>
      <div className='card-item flex-column pt-2'>
        <small>
          <p className='mb-0'>
            {stepTwoBillingAddress.name}<br />{stepTwoBillingAddress.businessName}
          </p>
          <p className='mb-0'>
            {stepTwoBillingAddress.streetAddress}<br />
            {stepTwoBillingAddress.addressLine2 && (stepTwoBillingAddress.addressLine2 && stepTwoBillingAddress.addressLine2)}
            {stepTwoBillingAddress.addressLine2 && <br />}
            {stepTwoBillingAddress.city} {stepTwoBillingAddress.province}<br />
            {stepTwoBillingAddress.postalCode}
          </p>
          <p className='mb-1'>
            +1 {stepTwoBillingAddress.phoneNumber}<br />{stepTwoBillingAddress.email}
          </p>
        </small>
      </div>
    </Card>
  );
};

export default BillingAddressCard;
