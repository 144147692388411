import React, { useEffect, useState } from 'react';
import { useLayoutEffect } from 'react';
import { useTheme } from '../context/theme-context';
import { Auth } from 'aws-amplify';

import {
  ShoppingCartOutlined,
  FormOutlined,
  BlockOutlined,
  SnippetsOutlined,
  TeamOutlined,
  PrinterOutlined,
  DollarOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';

import { NavLink } from 'react-router-dom';
import { UserData } from '../schema/user';
import { decryptString } from '../common/encryption';
import { Tooltip } from 'antd';

function SideBar() {
  const { toggle } = useTheme();
  const [user, setUser] = React.useState<UserData>();

  const routes = [
    {
      route: '/order-now',
      name: 'Order Now',
      access: ['User'],
      Tooltip: 'Order Now',
      icon: <ShoppingCartOutlined style={{ fontSize: '20px' }} />,
    },
    {
      route: '/personal-info',
      name: 'User Info',
      access: ['User'],
      Tooltip: 'User Info',
      icon: <FormOutlined style={{ fontSize: '20px' }} />,
    },
    {
      route: '/customers',
      name: 'Customers',
      access: ['Admin'],
      Tooltip: 'Customers',
      icon: <TeamOutlined style={{ fontSize: '20px' }} />,
    },
    {
      route: '/order-inventory',
      name: 'Inventory',
      access: ['Admin'],
      Tooltip: 'Inventory',
      icon: <SnippetsOutlined style={{ fontSize: '20px' }} />,
    },
    {
      route: '/orders',
      name: 'Orders',
      access: ['Admin'],
      Tooltip: 'Orders',
      icon: <BlockOutlined style={{ fontSize: '20px' }} />,
    },
    {
      route: '/print',
      name: 'Print',
      Tooltip: 'Print',
      access: ['Admin'],
      icon: <PrinterOutlined style={{ fontSize: '20px' }} />,
    },
    {
      route: '/taxes',
      name: 'Taxes',
      Tooltip: 'Taxes',
      access: ['Admin'],
      icon: <DollarOutlined style={{ fontSize: '20px' }} />,
    },
    {
      route: '/reports',
      name: 'Reports',
      Tooltip: 'Reports',
      access: ['Admin'],
      icon: <FileDoneOutlined style={{ fontSize: '20px' }} />,
    },
  ];
  const loggedUserDataStr = localStorage.getItem('loggedUserData');

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        const attributes = authenticatedUser.attributes;
      } catch (error) {
        // Handle error retrieving user attributes
        console.log(error);
      }
    };

    if (loggedUserDataStr) {
      const parsedData = JSON.parse(decryptString(loggedUserDataStr));
      setUser(parsedData);
    }
    fetchUserAttributes();
  }, [loggedUserDataStr]);

  useLayoutEffect(() => {
    const body = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    toggle ? body.classList.add('body-pd') : body.classList.remove('body-pd');
  }, [toggle]);

  if (user && user.role !== 'User') {
    return (
      <div className={`${toggle ? 'show' : ''} l-navbar `} id='nav-bar'>
        <nav className='nav primary-bg'>
          <div>
            <div className='nav_logo'></div>
            <div className='nav_list'>
              {user &&
                routes.map((r, i) => {
                  const userRole: any = user.role;
                  if (r.access?.includes(userRole)) {
                    return (
                      <Tooltip key={i} title={r.Tooltip}>
                        <NavLink
                          to={r.route}
                          className={({ isActive }) => (isActive ? 'nav_link active' : 'nav_link')}
                          key={i}
                        >
                          {r.icon}
                          <span className='nav_name'>{r.name}</span>
                        </NavLink>
                      </Tooltip>
                    );
                  } else {
                    return <span key={i} />;
                  }
                })}
            </div>
          </div>
        </nav>
      </div>
    );
  } else {
    return <span></span>;
  }
}

export default SideBar;
