import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, InputNumber, InputRef, Space, Table, message,Tag } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { OrderTier, Product } from '../../schema/order';
import { useStepper } from '../../context/stepper-context';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';

type OrderTableProps = {
  products: Product[];
  tiers: OrderTier[];
  isTierQtyBased: boolean;
  prodId: number;
  isTierSameForAll: boolean;
};

const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Product;
  record: Product;
  tiers: OrderTier[];
  handleSave: (record: Product) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  tiers,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = useContext(EditableContext)!;
  const [orderTiers, setOrderTiers] = useState<OrderTier[]>();
  const [inputValue] = useState<number>(0);
  const [recordId, setRecordId] = useState<number>(0);

  useEffect(() => {
    setOrderTiers(tiers);
  }, [dataIndex]);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async (record: any) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      const tier = orderTiers?.find(
        (obj) => obj.type?.toLowerCase() === record.type?.toLowerCase(),
      );
      const tierMultiple: any = tier?.multiple;
      if (values.quantity > 0) {
        let roundedValue = Math.ceil(values.quantity / tierMultiple) * tierMultiple;
        if (roundedValue > record.totalStock + record.bufferStock) {
          roundedValue = record.totalStock + record.bufferStock;
        }
        if (
          roundedValue > record.productLimit &&
          record.productLimit != 0 &&
          record.totalStock + record.inventoryBuffer > record.productLimit
        ) {
          roundedValue = record.productLimit;
        }
        form.setFieldsValue({ quantity: roundedValue });
        values.quantity = roundedValue;
      } else {
        form.setFieldsValue({ quantity: 0 });
        values.quantity = 0;
      }
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const generatePlaceHolder = (record: any) => {
    if (!record.quantity) {
      if (
        record.productLimit > 0 &&
        record.totalStock + record.inventoryBuffer > record.productLimit
      ) {
        return `Limit ${record.productLimit}`;
      } else if (record.maxThreshold >= record.totalStock + record.bufferStock) {
        return `Max ${record.totalStock + record.bufferStock}`;
      } else {
        return 'Available';
      }
    }
  };

  const getValue = (record: any) => {
    if (record.quantity && record.quantity != 0) {
      return record.quantity;
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0, width: '50px' }}
        name={dataIndex}
        initialValue={getValue(record)}
        rules={[
          () => ({
            validator(_, value) {
              if (record.productLimit != 0 && value > record.productLimit) {
                message.warning(
                  `Quantity has been reset to maximum amount available for ${record.name}`,
                  5,
                );
                form.setFieldsValue({ quantity: record.productLimit });
                return Promise.reject('Unavailable');
              }
              if (value > record.totalStock + record.bufferStock) {
                message.warning(
                  `Quantity has been reset to maximum amount available for ${record.name}`,
                  5,
                );
                form.setFieldsValue({ quantity: record.totalStock + record.bufferStock });
                return Promise.reject('Unavailable');
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <InputNumber
          width={'50%'}
          // type='number'
          ref={inputRef}
          min={0}
          placeholder={generatePlaceHolder(record)}
          value={inputValue}
          onPressEnter={() => save(record)}
          onBlur={() => save(record)}
        />
      </Form.Item>
    ) : (
      <Form.Item style={{ margin: 0, width: '50px' }} initialValue={getValue(record)}>
        <InputNumber
          ref={inputRef}
          placeholder={generatePlaceHolder(record)}
          defaultValue={getValue(record)}
          onClick={toggleEdit}
          readOnly
        ></InputNumber>
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function OrderTable({
  products,
  tiers,
  isTierQtyBased,
  isTierSameForAll,
  prodId,
}: OrderTableProps) {
  const [dataSource, setDataSource] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const { stepOneData, setStepOneData, subTotalAmount, setSubTotalAmount } = useStepper();
  const [sameTier, setSameTier] = useState<string>('');

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);

    if (
      stepOneData &&
      stepOneData.length > 0 &&
      (stepOneData[0]?.selectedQuantity ?? 0) > 0 &&
      (stepOneData[0]?.productId ?? 0) > 0
    ) {
      setDataSource(stepOneData);
    } else {
      setDataSource(products);
    }
  }, [products]);

  // ----------- Column Search Start -------------- //
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof Product,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: keyof Product): ColumnType<Product> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div className='d-flex gap-2' style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          className='mb-0'
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          onKeyDown={() => {
            confirm({ closeDropdown: false });
            setSearchText((selectedKeys as string[])[0]);
            setSearchedColumn(dataIndex);
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <CloseOutlined
            onClick={() => {
              close();
            }}
          />
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{ color: filtered ? '#1677ff' : undefined, fontSize: '16px', paddingRight: '10px' }}
      />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  // ----------- Column Search End-------------- //

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: ' Quantity ',
      dataIndex: 'quantity',
      editable: true,
      align: 'right',
      render: (text) => (text > 0 ? <span>{Number(text ? text : 0)}</span> : <span>&nbsp;</span>),
      width: 100,
    },
    {
      title: 'Variety',
      dataIndex: 'name',
      width: 400,
      ...(getColumnSearchProps('name') as ColumnTypes),
      render: (_: any, record: any) =>
        record.imageUrl ? (
          <a
            href={
              record.imageUrl.indexOf('http') > -1 ? record.imageUrl : `https://${record.imageUrl}`
            }
            target='_blank'
            rel='noreferrer'
          >
            {record.name}
          </a>
        ) : (
          <span>{record.name}</span>
        ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Description',
      dataIndex: 'notes',
    },
    {
      title: 'Size',
      dataIndex: 'size',
    },
    {
      title: 'Tier One',
      dataIndex: 'tier1Price',
      align: 'right',
      render: (text) => <span>${Number(text).toFixed(2)}</span>,
      width: 80,
    },
    {
      title: 'Tier Two',
      dataIndex: 'tier2Price',
      align: 'right',
      render: (text) => (
        <span>{Number(text).toFixed(2) === '0.00' ? '-' : `$${Number(text).toFixed(2)}`}</span>
      ),
      width: 80,
    },
    {
      title: 'Tier Three',
      dataIndex: 'tier3Price',
      align: 'right',
      render: (text) => (
        <span style={{ textAlign: Number(text).toFixed(2) === '0.00' ? 'center' : 'right' }}>
          {Number(text).toFixed(2) === '0.00' ? '-' : `$${Number(text).toFixed(2)}`}
        </span>
      ),
      width: 80,
    },
  ];

  const totalQuantity: number = dataSource.reduce(
    (index: number, item) =>
      item.quantity ? Number(index) + Number(item.quantity) : Number(index),
    0,
  );

  const handleSave = (product: Product) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => product.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...product,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const calculateTierPrice = (item: Product, typeQty: number, itemQuantity: number) => {
    const tier = tiers.find((obj) => obj.type?.toLowerCase() === item.type?.toLowerCase());

    let pricePerPiece: any = 0;
    let tierSame: any = '';
    if (isTierQtyBased) {
      if (tier) {
        if (isTierSameForAll) {
          if (tier.tier1Qty) {
            pricePerPiece = item.tier1Price;
            tierSame = 'One';
          }
          if (tier.tier2Qty && totalQuantity >= tier.tier2Qty && tier.tier2Qty != 0) {
            pricePerPiece = item.tier2Price;
            tierSame = 'Two';
          }
          if (tier.tier3Qty && totalQuantity >= tier.tier3Qty && tier.tier3Qty != 0) {
            pricePerPiece = item.tier3Price;
            tierSame = 'Three';
          }
        } else {
          if (tier.tier1Qty) {
            pricePerPiece = item.tier1Price;
          }
          if (tier.tier2Qty && typeQty >= tier.tier2Qty && tier.tier3Qty == 0) {
            pricePerPiece = item.tier2Price;
          }
          if (
            tier.tier2Qty &&
            tier.tier3Qty &&
            typeQty >= tier.tier2Qty &&
            typeQty < tier.tier3Qty
          ) {
            pricePerPiece = item.tier2Price;
          }
          if (tier.tier3Qty && typeQty >= tier.tier3Qty && tier.tier3Qty !== 0) {
            pricePerPiece = item.tier3Price;
          }
        }

        setSameTier(tierSame);
        return pricePerPiece;
      }
    } else {
      if (tier) {
        if (itemQuantity) {
          pricePerPiece = item.tier1Price;
        }
        if (itemQuantity && tier.tier2Qty && itemQuantity >= tier.tier2Qty) {
          pricePerPiece = item.tier2Price;
        }
        if (itemQuantity && tier.tier3Qty && itemQuantity >= tier.tier3Qty) {
          pricePerPiece = item.tier3Price;
        }
        return pricePerPiece;
      }
    }
  };

  useEffect(() => {
    const filteredRecords = dataSource
      .filter((record) => record.quantity && record.quantity > 0)
      .map((record) => ({
        ...record,
        productId: prodId,
      }));

    if (dataSource.length > 0) {
      const dataWithProductId = dataSource.map((rec) => ({
        ...rec,
        productId: prodId,
        selectedQuantity: filteredRecords.length,
      }));

      setStepOneData(dataWithProductId);
    }
    const reducedRecord = filteredRecords.reduce<any>((acc, curr) => {
      const { type, quantity } = curr;
      acc[type!] = (acc[type!] || 0) + Number(quantity!);
      return acc;
    }, {});

    const subTotal: number = filteredRecords.reduce(
      (acc: number, item) =>
        item.quantity
          ? Number(acc) +
            Number(item.quantity) *
              Number(calculateTierPrice(item, reducedRecord[item.type!], item.quantity))
          : Number(acc),
      0,
    );
    setSubTotalAmount(subTotal);
  }, [dataSource]);

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Product) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        tiers: tiers,
      }),
    };
  });

  return (
    <div>
      <Table
        loading={loading}
        components={components}
        rowClassName={() => 'editable-row'}
        rowKey='id'
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        pagination={false}
        scroll={{
          y: 550,
        }}
      />
      <div className='sticky-footer d-flex' style={{ justifyContent: 'space-between' }}>
        <div>
          Item Count:<b> {totalQuantity}</b>
          {sameTier && isTierQtyBased && isTierSameForAll && <span> (Tier {sameTier})</span>}
        </div>
        <div>
          Sub Total:<b> ${subTotalAmount.toFixed(2)} </b>
        </div>
      </div>
    </div>
  );
}

export default OrderTable;
