export const customerData = 'user';
export const changePassword = 'user/changepassword';
export const getUserInfo = 'userbyid';
export const updateUserInfo = 'user';
export const checkUser = 'user/check';
export const userNotes = 'userNotes';


export const getOrderForms = 'orderform';
export const confirmPassword = 'orderform/confirmpass';
export const getOrderFormProducts = 'orderform/products';

export const bulkReminder = 'order/reminder/bulk';
export const emailInvoice = 'order/invoice/email';
export const orderReminder = 'order/reminder';
export const getTerms = 'productline/terms';
export const sendAcknowledgement = 'order/acknowledgement';

export const calculateOrder = 'order/calculate';
export const calculateExisting = 'order/calculateexisting';
export const orderExisting = 'order/addexisting';
export const placeOrder = 'order';
export const orderList = 'order/list';
export const orderDetails = 'order';
export const bulkQuantity = 'order/qunatiity/bulk';
export const addProductByAdmin = 'order/addbyadmin';

export const tax = 'tax';

export const getAllProductLines = 'productline';

export const getDistinctYear = 'orderform/distintyear';
export const getOrderFormsByYear = 'orderform/year';
export const getOrderFormForAdmin = 'orderform/admin';
export const cancelOrder = 'order/cancel';
export const updateProduct = 'order/product';
export const invoiceUpdateEmail = 'order/invoice/update/email';
export const replaceOrder = 'order/replace';
export const replaceSomeOrders = '/order/replacesome';

export const payment = 'payment';
export const updateOrderFormForAdmin = 'orderform/admin';
export const updateOrderFormInventory = 'orderform/inventory';
export const deleteInventory = 'inventory';

export const productDiscounts = 'discount';
export const productFiles = 'orderformfile';
export const productTiers = 'orderformtier';
export const inventory = 'orderform/inventory';
export const status = 'order/status';
export const orderSticker = 'order/stickers';

export const newOrderForm = 'orderform';
export const getAllOrdersForProduct = 'orderform/inventory/orders';

export const exportOrders = 'export/orders';
export const exportProductLabels = 'export/products';
export const exportScrap = 'export/scrap';
export const exportAccountingAndOrderStatus = 'export/accounting';
