import React from 'react';
import { useStepper } from '../../context/stepper-context';
import { Card } from 'antd';
import { UserData } from '../../schema/user';


type shippingAddress = {
    shippingAddress: UserData;
};

const shippingAddress = ({ shippingAddress }: shippingAddress) => {

    return (
        <Card className='h-100' style={{ width: 400 }}>
            <span className='secondary-color'>
                <b>Shipping Details</b>
            </span>
            <div>
                <div className='card-item flex-column pt-2'>
                    <div>
                        <p className='mb-1 d-flex'>
                            <span>{shippingAddress.name}</span>
                        </p>
                        <p className='mb-1 d-flex'>
                            <span>{shippingAddress.businessName}</span>
                        </p>
                        <p className='mb-1 d-flex'>
                            <span>{shippingAddress.streetAddress}</span>
                        </p>
                        {shippingAddress.addressLine2 && <p className='mb-1 d-flex'>
                            <span>{shippingAddress.addressLine2 && shippingAddress.addressLine2}</span>
                        </p>}
                        <p className='mb-1 d-flex'>
                            <span>{`${shippingAddress.city} ${shippingAddress.province}`}</span>
                        </p>
                        <p className='mb-1 d-flex'>
                            <span>{shippingAddress.postalCode}</span>
                        </p>
                        <p className='mb-1 d-flex'>
                            <span>+1 {shippingAddress.phoneNumber}</span>
                        </p>
                        <p className='mb-1 d-flex'>
                            <span>{shippingAddress.email}</span>
                        </p>
                    </div>

                </div>
            </div>
        </Card>
    );
};

export default shippingAddress;
