export const province = [
  { id: 'AB', value: 'AB', label: 'Alberta' },
  { id: 'BC', value: 'BC', label: 'British Columbia' },
  { id: 'MB', value: 'MB', label: 'Manitoba' },
  { id: 'NB', value: 'NB', label: 'New Brunswick' },
  { id: 'NL', value: 'NL', label: 'Newfoundland' },
  { id: 'NS', value: 'NS', label: 'Nova Scotia' },
  { id: 'NU', value: 'NU', label: 'Nunavut' },
  { id: 'NWT', value: 'NWT', label: 'Northwest Territories' },
  { id: 'ON', value: 'ON', label: 'Ontario' },
  { id: 'PEI', value: 'PEI', label: 'Prince Edward Island' },
  { id: 'SK', value: 'SK', label: 'Saskatchewan' },
  { id: 'QC', value: 'QC', label: 'Quebec' },
  { id: 'YK', value: 'YK', label: 'Yukon' },
];
