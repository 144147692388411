import axios, { AxiosResponse } from 'axios';
import config from './config';
import { Auth } from 'aws-amplify';

// Create an instance of Axios
const api = axios.create({
  baseURL: config.getHost(),
});

// Add a request interceptor
api.interceptors.request.use(
  async function (config: any) {
    const session = await Auth.currentSession();
    const access = session.getIdToken();
    const jwtToken = access.getJwtToken();
    // console.log('Session TOKEN', jwtToken);

    const accessToken = jwtToken;

    let headers;

    if (config.url !== 'login') {
      headers = {
        Authorization: accessToken,
        'Content-Type': 'application/json',
      };
    }
    // Modify the request config if needed (e.g., add headers, authentication, etc.)
    return { ...config, headers };
  },

  function (error) {
    // Handle request error
    console.error('Request Interceptor Error:', error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
api.interceptors.response.use(
  function (response: AxiosResponse) {
    // Process the response data if needed
    return response?.data;
  },

  function (error) {
    // Handle response error
    console.error('Response Interceptor Error:', error);
    return Promise.reject(error);
  },
);

export default api;
