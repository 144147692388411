import { Tabs, TabsProps } from 'antd';
import React from 'react';
import OrderReport from './orderReport';
import ProductLabelsReport from './productLabelsReport';
import ScrapReport from './scrapReport';
import AccountingAndOrderStatusReport from './accountingAndOrderStatusReport';

const Reports = () => {
  const Tabitems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Order Details',
      children: <OrderReport />,
    },
    {
      key: '2',
      label: 'Accounting and order Status',
      children: <AccountingAndOrderStatusReport />,
    },
    {
      key: '3',
      label: 'Product Labels',
      children: <ProductLabelsReport />,
    },
    {
      key: '4',
      label: 'Scrap Report',
      children: <ScrapReport />,
    },
  ];

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'>
        <h3 className='title-grey'>Reports</h3>
      </div>
      <Tabs
        defaultActiveKey='1'
        className='report-tabs'
        type='card'
        animated
        destroyInactiveTabPane
        items={Tabitems}
      />
    </div>
  );
};

export default Reports;
