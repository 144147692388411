import { Button, Card, Form, Input, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { province } from '../../common/province';
import api from '../../config/axios';
import { getUserInfo, updateUserInfo } from '../../config/api-routes';
import { UserData } from '../../schema/user';
import Title from 'antd/es/typography/Title';
import Spinner from '../../layout/spinner';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 17 },
};

let userAttributes: any = {};

//class AddressInfo extends React.Component {
const AddressInfo: React.FC = () => {
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveShippingInfo = async (values: any) => {
    setLoading(true);
    await api
      .put(updateUserInfo, values)
      .then(() => {
        message.success('Updated successfully!');
        fetchData();
      })
      .catch((error) => {
        message.error(error);
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await api.post<never, UserData>(getUserInfo, {}).then((res) => {
      const data = res;
      if (data) {
        userAttributes = data;
        populateFormValues();
        setLoading(false);
      }
    });
  };

  const handleFormChange = () => {
    setIsDirty(form.isFieldsTouched());
  };

  const populateFormValues = () => {
    form.setFieldsValue({
      name: userAttributes.name,
      businessName: userAttributes.businessName,
      email: userAttributes.email,
      phoneNumber: userAttributes.phoneNumber,
      streetAddress: userAttributes.streetAddress,
      addressLine2: userAttributes.addressLine2,
      city: userAttributes.city,
      postalCode: userAttributes.postalCode,
      taxNo: userAttributes.taxNo,
      province: userAttributes.province,
    });
  };

  const checkForBlankSpace = (rule: any, value: string) => {
    return new Promise<void>((resolve, reject) => {
      if (value && value.trim() === '') {
        reject('Field value cannot have a space');
      } else {
        resolve();
      }
    });
  };

  const handleKeyPress = (e: any) => {
    const keyCode = e.which || e.keyCode; // Get the key code
    // Allow only non-numeric characters
    if ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || keyCode == 8) {
      // Alphabetic characters
    } else {
      e.preventDefault(); // Prevent the key press for all other characters
    }
  };

  const validatePhoneNumber = (rule: any, value: string) => {
    return new Promise<void>((resolve, reject) => {
      const phoneRegex = /^(\(\+[0-9]{2}\))?([0-9]{3}-?)?([0-9]{3})-?([0-9]{4})(\/[0-9]{4})?$/;
      if (!value || phoneRegex.test(value)) {
        resolve();
      } else {
        reject('Please enter a valid phone number');
      }
    });
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    ); // Display a loader while loading
  }

  return (
    <div className='mt-4'>
      <Card hoverable bordered title={<Title level={3}>Shipping Information</Title>}>
        <Form
          {...layout}
          form={form}
          name='control-ref'
          onFinish={saveShippingInfo}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            name='email'
            label='Email'
            rules={[
              { type: 'email', required: true, message: 'Please enter email!' },
              {
                validator: checkForBlankSpace,
              },
            ]}
          >
            <Input className='fw-600' disabled />
          </Form.Item>
          <Form.Item
            name='name'
            label='Contact Name'
            rules={[
              { required: true, message: 'Please enter contact name!' },
              {
                validator: checkForBlankSpace,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='businessName'
            label='Business Name'
            rules={[
              { required: true, message: 'Please enter business name!' },
              {
                validator: checkForBlankSpace,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='phoneNumber'
            label='Phone Number'
            rules={[
              { required: true, message: 'Please enter phone number!' },
              {
                validator: checkForBlankSpace,
              },
              {
                validator: validatePhoneNumber,
              },
            ]}
          >
            <Input addonBefore='+1' type='tel' />
          </Form.Item>
          <Form.Item
            name='streetAddress'
            label='Street Address'
            rules={[
              { required: true, message: 'Please enter street address!' },
              {
                validator: checkForBlankSpace,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='addressLine2' label='Address Line 2'>
            <Input />
          </Form.Item>
          <Form.Item
            name='city'
            label='City'
            rules={[
              { required: true, message: 'Please enter city!' },
              {
                validator: checkForBlankSpace,
              },
            ]}
          >
            <Input onKeyDown={handleKeyPress} />
          </Form.Item>
          <Form.Item
            name='postalCode'
            label='Postal Code'
            rules={[
              { required: true, message: 'Please enter postal code!' },
              {
                validator: checkForBlankSpace,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='taxNo'
            label='Tax No'
            rules={[
              { message: 'Please enter tax number!' },
              {
                validator: checkForBlankSpace,
              },
            ]}
            help='Please enter your tax no if you are tax exempted'
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='province'
            label='Province'
            rules={[{ required: true, message: 'Please select province!' }]}
          >
            <Select placeholder={'Select Province'} options={province}></Select>
          </Form.Item>

          <Form.Item className='text-center'>
            <Button onClick={populateFormValues} disabled={!isDirty} className='mx-2'>
              Cancel
            </Button>
            <Button type='primary' htmlType='submit' className='mx-2'>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AddressInfo;
