import React, { useState, useEffect } from 'react';
import AddressForm from './shippingAddress';
import TextArea from 'antd/es/input/TextArea';
import { Order, PastOrder } from '../../schema/order';
import api from '../../config/axios';
import { UserData } from '../../schema/user';
import { getUserInfo } from '../../config/api-routes';
import propTypes from 'prop-types';
import { useStepper } from '../../context/stepper-context';
import WeekDropdown from './weekDropdown';
import { Button, Checkbox, FormInstance, Modal, Typography, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Spinner from '../../layout/spinner';
import Details from './shippingAddressDetails';
import { useParams } from 'react-router-dom';

type StepTwoProps = {
  order: Order;
  onChange: (data: any) => void;
  form: FormInstance;
  goNext: () => void;
  gotoNextStepWithAPI: () => void;
};

function StepTwo({ order, form, goNext, gotoNextStepWithAPI }: StepTwoProps) {
  const [loading, setLoading] = useState(false);
  const { customerId } = useParams();

  const [billingAddress, setBillingAddress] = useState<UserData>({
    name: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    userCreateDate: '',
    addressLine2: '',
    taxNo: '',
    province: '',
    userLastModifiedDate: '',
    enabled: false,
    userStatus: '',
    postalCode: '',
    tax: '',
    city: '',
  });

  const [shippingAddress, setShippingAddress] = useState<UserData>({
    name: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    userCreateDate: '',
    addressLine2: '',
    taxNo: '',
    province: '',
    userLastModifiedDate: '',
    enabled: false,
    userStatus: '',
    postalCode: '',
    tax: '',
    city: '',
  });

  const {
    stepTwoDeliveryNotes,
    setStepTwoBillingAddress,
    setShippingAddressDetails,
    setStepTwoShippingAddress,
    setStepTwoDeliveryNotes,
    setShippingSameAsBilling,
    sameBillingAddress,
    setClear,
    pastOrders,
    stepTwoBillingAddress,
    shippingAddressDetails
  } = useStepper();

  useEffect(() => {
    if (sameBillingAddress) {
      setStepTwoShippingAddress(stepTwoBillingAddress);
    }
  }, [stepTwoBillingAddress, sameBillingAddress]);

  useEffect(() => {
    setLoading(true);
    api
      .post<never, UserData>(getUserInfo, { cognitoId: customerId })
      .then((res) => {
        setBillingAddress(res);
        setStepTwoBillingAddress(res);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        message.error(e);
      });
  }, []);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const { Text } = Typography;

  return (
    <div>
      <div className='form-content'>
        {pastOrders &&
          pastOrders.map((order: PastOrder, index: any) => (
            <div className='d-flex' key={index}>
              <Text mark>
                You already have an order shipping in week {(order.shipWeek / 10000).toFixed()}
              </Text>
            </div>
          ))}
      </div>
      <div className='form-content d-flex' style={{ justifyContent: 'start' }}>
        <Details shippingAddress={billingAddress} />
        <span className='align-items-center drop-down-right form-content' style={{ width: '25%' }}>
          <span className='p-2 fw-600'>Requested ship week</span>
          <span className='week-dropdown'>
            <WeekDropdown
              startingWeek={order.shippingWeek}
              endShippingWeek={order.endShippingWeek}
              disabled={pastOrders.length > 0}
            />
          </span>
        </span>
      </div>
      <div className='pt-2'>
        <span className='fw-600'>Delivery Notes</span>
        <TextArea
          placeholder='Add a delivery note'
          rows={4}
          value={stepTwoDeliveryNotes}
          onChange={(event) => {
            setStepTwoDeliveryNotes(event.target.value);
          }}
        />
      </div>
    </div>
  );
}

StepTwo.propTypes = {
  shippingAddress: propTypes.any,
  startingWeek: propTypes.any,
  clear: propTypes.any,
  endShippingWeek: propTypes.any,
};

export default StepTwo;
