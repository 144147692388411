import React, { useEffect, useState } from 'react';
import BillingAddressCard from './shippingAddressCard';
import ShippingAddressCard from './shippingAddressCard';
import { useStepper } from '../../context/stepper-context';
import { Button, Card, Checkbox, Modal, Table, Typography } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../config/axios';
import { getTerms } from '../../config/api-routes';
import { PlusOutlined } from '@ant-design/icons';
import { Order } from '../../schema/order';

type StepThreeProps = {
  order: Order;
  heatedPackageCallback: (e: boolean) => void;
};
function StepThree({ order, heatedPackageCallback }: StepThreeProps) {
  const { stepTwoShippingWeek, stepTwoDeliveryNotes } = useStepper();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReHeatedPackage, setIsReHeatedPackage] = useState(false);
  const [terms, setTerms] = useState('');
  const navigate = useNavigate();
  const { customerId } = useParams();

  const match = /^(\d+)/.exec(stepTwoShippingWeek);

  const {
    stepOneRes,
    setTermsConditions,
    setCurrentStep,
    setShowFinalModalPopUp,
    stepTwoBillingAddress,
    showFinalModalPopUp,
    title,
    stepTwoRes,
    pastOrders,
    stepTwoShippingYear,
  } = useStepper();

  const pastOrderShipWeek = pastOrders.length
    ? Math.round(pastOrders[0].shipWeek / 10000).toString()
    : null;

  interface WeekData {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
  }

  const getWeekDates = (weekNumber: number): WeekData => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    let pastOrderWeek = null;
    let pastOrderYear: RegExpMatchArray | null | undefined;

    if (pastOrders.length) {
      pastOrderWeek = pastOrders[0].shipWeek;
      pastOrderYear = String(pastOrderWeek).match(/(\d{4})$/);
    }

    const currentYear = pastOrders.length
      ? Number(pastOrderYear ? pastOrderYear[0] : null)
      : Number(stepTwoShippingYear);

    const firstDayOfYear = new Date(currentYear, 0, 1);

    const dayOfWeek = firstDayOfYear.getDay();
    const daysToAdd = (weekNumber - 1) * 7 - dayOfWeek + 1;

    const startDate = new Date(currentYear, 0, 1 + daysToAdd);
    const endDate = new Date(currentYear, 0, 1 + daysToAdd + 6);

    return {
      weekNumber,
      startDate,
      endDate,
    };
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      render: (text: number) => <span>${Number(text).toFixed(2)}</span>,
      align: 'right' as AlignType,
      key: 'unitPrice',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      render: (text: number) => <span>${Number(text).toFixed(2)}</span>,
      align: 'right' as AlignType,
      key: 'cost',
      width: 200,
    },
  ];

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setTermsConditions(true);
    } else {
      setTermsConditions(false);
    }
  };

  const handleIsReHeatedCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setIsReHeatedPackage(true);
      heatedPackageCallback(true);
    } else {
      setIsReHeatedPackage(false);
      heatedPackageCallback(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const goToStepOne = () => {
    setTermsConditions(false);
    setCurrentStep(0);
  };

  const handleFinalModalCancel = () => {
    navigate('/');
    setShowFinalModalPopUp(false);
  };
  const handleFinalModalOk = () => {
    navigate('/');
    setShowFinalModalPopUp(false);
  };

  const shippingWeek = match
    ? getWeekDates(Number(match?.[1]))
    : getWeekDates(Number(pastOrderShipWeek));

  useEffect(() => {
    api.get<never, any>(getTerms + '/' + stepOneRes.productLineId).then((res: any) => {
      setTerms(res.terms);
    });
  }, []);

  const { Text } = Typography;

  return (
    <div>
      <div className='card-area'>
        <div className='card-block-step3 px-3'>
          <BillingAddressCard />
        </div>
        <div className='card-block-step3 px-3'>
          <Card className='h-100'>
            <div className='secondary-color'>
              <b>Requested Ship Week</b>
            </div>
            <div>
              <small>
                {shippingWeek.weekNumber} ({moment(shippingWeek.startDate).format('LL')} -{' '}
                {moment(shippingWeek.endDate).format('LL')})
              </small>
            </div>

            {stepTwoDeliveryNotes && (
              <div>
                <hr className='my-2' />
                <div className='secondary-color'>
                  <b>Delivey Notes</b>
                </div>
                <div>
                  <small>{stepTwoDeliveryNotes}</small>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
      {pastOrders.length > 0 && (
        <div className='px-3 pt-2'>
          <>
            <Text mark>Selected order items have been merged with already existing order</Text>
          </>
        </div>
      )}
      <div className='px-3 pt-2'>
        <Table
          dataSource={pastOrders.length > 0 ? stepTwoRes.products : stepOneRes.products}
          columns={columns}
          rowKey='unitPrice'
          pagination={false}
          summary={() => (
            <>
              {pastOrders.length > 0 && (
                <Table.Summary>
                  <Table.Summary.Row key='subTotal'>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <b>SubTotal :</b> <small>(Pre-Tax)</small>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align='right'>
                      ${stepTwoRes.totalGoodsAmt.toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  {stepTwoRes.dis !== 0 && (
                    <Table.Summary.Row key='discount'>
                      <Table.Summary.Cell index={0} colSpan={5}>
                        <b>Discount ({stepTwoRes.dis.toFixed(2)}%) :</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} colSpan={1} align='right'>
                        ${stepTwoRes.disAmt.toFixed(2)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}

                  <Table.Summary.Row key='taxes'>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <b>Taxes :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align='right'>
                      TBD
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row key='shipping'>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <b>Shipping :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align='right'>
                      TBD
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row key='grandTotal'>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <b>Grand Total : </b>
                      <small>(Exclusive of Taxes and Shipping)</small>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align='right'>
                      <b>${stepTwoRes.billableAmt.toFixed(2)}</b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}

              {!pastOrders.length && (
                <Table.Summary>
                  <Table.Summary.Row key='subTotal'>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <b>SubTotal :</b> <small>(Pre-Tax)</small>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align='right'>
                      ${stepOneRes.totalGoodsAmt.toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  {stepOneRes.dis !== 0 && (
                    <Table.Summary.Row key='discount'>
                      <Table.Summary.Cell index={0} colSpan={5}>
                        <b>Discount ({stepOneRes.dis.toFixed(2)}%) :</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} colSpan={1} align='right'>
                        ${stepOneRes.disAmt.toFixed(2)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}

                  <Table.Summary.Row key='taxes'>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <b>Taxes :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align='right'>
                      TBD
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row key='shipping'>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <b>Shipping :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align='right'>
                      TBD
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row key='grandTotal'>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <b>Grand Total : </b>
                      <small>(Exclusive of Taxes and Shipping)</small>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={1} align='right'>
                      <b>${stepOneRes.billableAmt.toFixed(2)}</b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            </>
          )}
        />
        <Modal
          title='To finalize your booking, please accept the following Terms & Conditions:'
          open={isModalVisible}
          onCancel={handleCancel}
          onOk={handleModalOk}
          maskClosable={false}
          footer={[
            <Button key='ok' type='primary' onClick={handleModalOk}>
              OK
            </Button>,
          ]}
        >
          <span>
            <hr />
            <p dangerouslySetInnerHTML={{ __html: terms }}></p>
          </span>
        </Modal>
        <div className='checkbox-button d-flex align-items-right py-3'>
          <div className='d-grid'>
            <Button type='primary' icon={<PlusOutlined />} onClick={goToStepOne}>
              Add More Items
            </Button>
            {order.allowHeated && (
              <>
                <Checkbox className='pt-3' onChange={handleIsReHeatedCheckboxChange}>
                  Ship this order in heat Packs
                </Checkbox>
              </>
            )}
            <Checkbox onChange={handleCheckboxChange}>
              I accept the{' '}
              <a className='link' onClick={showModal}>
                terms and conditions
              </a>
            </Checkbox>
          </div>
        </div>
        {!customerId && (
          <Modal
            title={`Thank you ${stepTwoBillingAddress.name}`}
            open={showFinalModalPopUp}
            onCancel={handleFinalModalCancel}
            onOk={handleFinalModalOk}
            maskClosable={false}
            footer={[
              <Button key='ok' type='primary' onClick={handleFinalModalOk}>
                OK
              </Button>,
            ]}
          >
            <p>
              We have received your order for {title}. You should receive a confirmation email
              shortly. If you do not receive an email within 10 minutes, please contact
              orders@unicornblooms.com (Order Emails are occasionally sent to spam folder)
            </p>
          </Modal>
        )}

        {customerId && (
          <Modal
            title={'Thank you'}
            open={showFinalModalPopUp}
            onCancel={handleFinalModalCancel}
            onOk={handleFinalModalOk}
            maskClosable={false}
            footer={[
              <Button key='ok' type='primary' onClick={handleFinalModalOk}>
                OK
              </Button>,
            ]}
          >
            <p>Order has been Placed for {stepTwoBillingAddress.businessName}</p>
          </Modal>
        )}
      </div>
    </div>
  );
}
StepThree.propTypes = {};

export default StepThree;
