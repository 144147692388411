import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, Button, Select, InputNumber } from 'antd';

interface EditAddTiersProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (item: any) => void;
  onCancelForm: () => void; // New prop to handle form reset
  initialValues?: any;
  popupTitle: string;
  sameTiers?: boolean;
  tierLength?: number;
  loading: boolean;
  formType?: string;
}

const EditAddTiers: React.FC<EditAddTiersProps> = ({
  visible,
  onCancel,
  onSave,
  initialValues,
  popupTitle,
  sameTiers,
  tierLength,
  loading,
  formType,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef<HTMLFormElement | any>(null);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, visible]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values: any) => {
        initialValues ? onSave({ ...values, id: initialValues.id }) : onSave(values);
      })
      .catch((error: any) => {
        return false;
      });
  };

  const validateGreaterThanZero = (_: any, value: number) => {
    if (value && value > 0) {
      return Promise.resolve();
    }
    return Promise.reject('Value must be greater than 0');
  };
  const handleKeyDown = (e: any) => {
    // Prevent negative sign '-' from being entered manually
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  const validateWhiteSpace = (_: any, value: string) => {
    if (value && value.trim() === '') {
      return Promise.reject('Input cannot consist only of spaces');
    }
    return Promise.resolve();
  };

  const parseNumber = (value: any) => {
    // Remove leading zeros using JavaScript's parseInt
    return parseInt(value, 10);
  };

  return (
    <Modal
      open={visible}
      title={initialValues ? `Edit ${popupTitle}` : `Add ${popupTitle}`}
      onCancel={onCancel}
      onOk={handleSave}
      destroyOnClose
      footer={[
        <Button key='back' onClick={onCancel}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={loading} onClick={handleSave}>
          {initialValues ? 'Update' : 'Submit'}
        </Button>,
      ]}
    >
      <Form form={form} ref={formRef} name='tiersForm' layout='vertical'>
        <Form.Item
          name='type'
          label='Type'
          rules={[{ required: true, message: 'Please enter type', validator: validateWhiteSpace }]}
        >
          <Input disabled={formType == 'add'} />
        </Form.Item>

        <Form.Item
          name='maxThreshold'
          label='Max threshold'
          rules={[
            { required: true, message: 'Please enter max threshold' },
            {
              validator: validateGreaterThanZero,
            },
          ]}
        >
          <InputNumber
            className='width-100'
            addonBefore={'$'}
            min={0}
            onKeyDown={handleKeyDown}
            parser={parseNumber}
          />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {() => {
            return (initialValues && sameTiers) || tierLength == 0 || !sameTiers ? (
              <Form.Item
                name='tier1Qty'
                label='Tier 1 quantity'
                rules={[
                  { required: true, message: 'Please enter tier 1 quantity' },
                  {
                    validator: validateGreaterThanZero,
                  },
                ]}
              >
                <InputNumber
                  className='width-100'
                  min={0}
                  onKeyDown={handleKeyDown}
                  parser={parseNumber}
                />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {() => {
            return (initialValues && sameTiers) || tierLength == 0 || !sameTiers ? (
              <Form.Item
                name='tier2Qty'
                label='Tier 2 quantity'
                rules={[
                  { required: true, message: 'Please enter tier 2 quantity' },
                ]}
              >
                <InputNumber
                  className='width-100'
                  min={0}
                  onKeyDown={handleKeyDown}
                  parser={parseNumber}
                />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {() => {
            return (initialValues && sameTiers) || tierLength == 0 || !sameTiers ? (
              <Form.Item
                name='tier3Qty'
                label='Tier 3 quantity'
                rules={[{ required: true, message: 'Please enter tier 3 quantity' }]}
              >
                <InputNumber
                  className='width-100'
                  min={0}
                  onKeyDown={handleKeyDown}
                  parser={parseNumber}
                />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>

        <Form.Item
          name='multiple'
          label='Multiple'
          rules={[
            { required: true, message: 'Please enter multiple' },
            {
              validator: validateGreaterThanZero,
            },
          ]}
        >
          <InputNumber
            className='width-100'
            min={0}
            onKeyDown={handleKeyDown}
            parser={parseNumber}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAddTiers;
